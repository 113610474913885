import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './Supportingfiles/navbar.js';
import Sidebar from './Supportingfiles/sidebar.js';
//import Create_loanrequests from './create_newaccounts';


//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';



function Employee_part_payment(){

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");
 const loan_number=sessionStorage.getItem("LNO00");
 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable

//Default Load
const[loan_amount,setloan_amount]=useState();
const[closure_interest,setclosure_interest]=useState();
const[closure_charge,setclosure_charge]=useState();
const[closure_amount,setclosure_amount]=useState();
const[remaining_amount,setremaining_amount]=useState();
const[total_emis,settotal_emis]=useState();
const[emis_paid,setemis_paid]=useState();
const[emis_due,setemis_due]=useState();
const[duration,setduration]=useState();
const[emi,setemi]=useState();


useEffect(()=>{
   
    getData();
    getData1();
    
    },[])


    

//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(branch_id>0)){
 
 axios.post("crm-employee-get-banks-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,
   'Branch_id' : branch_id


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//



     

//******************* GET LOAN CLOSURE DATA FUNCTION START  ********************//
function getData1(){


    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(branch_id>0)&&((loan_number!=null)||(loan_number!=" "))){
   
   axios.post("crm-employee-loan-partpayment-default-load", {
     Log_id:logid,
     Token:token,
     'Loan_no' : loan_number,
     'Branch_id' : branch_id
  
  
     })
   .then((response) => {
  
    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("logName00");
  
       window.location.replace("/");
    }
    //Success Data Fetched
    if(response.data.successvar==1){

     //Setting Data
     setloan_amount(response.data.loan_amount);
     setemi(response.data.emi);
     settotal_emis(response.data.total_emis);
     setemis_paid(response.data.emis_paid);
     setemis_due(response.data.emis_due);
     setduration(response.data.duration);
     setremaining_amount(response.data.remaining_amount);

    }
    if(response.data.error=="103"){
        seterror_msg(response.data.error_msg);
    }
    //FILTER DATA
    //Validation Errors
    if(response.data.validatorerror){
  
    
      seterrorvar("Validation error!");
  
    }
  
    //Data Violation
    if(response.data.error==102){ 
    
       //Setting Filter Violation Errors
       seterrorvar("Violation Error");
  
  
    }
  
  
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
  
     window.location.replace("/");
   
    }
   
   }
    
   //******************* GET LOAN CLOSURE DATA FUNCTION END  ********************//


 
  //SETTING DATA VARIABLES
  const[pay_emis,setpay_emis]=useState();const updatepay_emis = (event) => { setpay_emis(event.target.value); calculate(); }; const[pay_emis_er,setpay_emis_er]=useState();
  const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
  const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
  const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
  const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);}; const[amount_er,setamount_er]=useState();
  const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
  const[bank_name,setbank_name]=useState();//const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();

  
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

 //image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
 const[attachment_link_er,setattachment_link_er]=useState();

 
function calculate(){
    const emis_count = pay_emis;
    const amt = emis_count*emi;
    setamount(amt);
}
 
/***************** PAY EMI FUNCTION START  ******************/
function pay_partpayment(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(branch_id)&&((loan_number!=null)||(loan_number!=" "))){


    //Setting Error Variables
    setdate_er(" ");settype_er(" ");setparticular_er(" ");setbank_id_er(" "); seterror_msg(" ");setpay_emis_er(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $pay_emis = document.getElementById("pay_emis").value; if($pay_emis==""){ setdate_er("Part Pay EMIs is required !");noerror = noerror+1; }//pay emis
const $date = document.getElementById("date").value; if($date==""){ setdate_er("Date is required !");noerror = noerror+1; }//date
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//type
const $particular = document.getElementById("particular").value; if($particular==""){ setparticular_er("Particular is required !");noerror = noerror+1; }//particular
//const $amount = document.getElementById("amount").value; if($amount==""){ setamount_er("Amount is required !");noerror = noerror+1; }//amount
const $bank_id = document.getElementById("bank_id").value; //if($bank_id==""){ setbank_id_er("Bank is required !");noerror = noerror+1; }//bank_id
 if($pay_emis>emis_due){ setpay_emis_er("Part Pay EMIs cannot be more than Due EMIs !");noerror = noerror+1; }
//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-employee-create-loan-partpayment", { 
  Log_id:logid,
  Token:token,
  Loan_no:loan_number,
  Date:$date,
  Type:$type,
  Particular:$particular,
  //Amount:$amount,
  Bank_id:$bank_id,
  File_attachment:selectedFile,
  Branch_id:branch_id,
  Loan_no:loan_number,
  Pay_emis: $pay_emis
 
  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

    
  console.log(response);

  //Validation Errors
  if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  settype_er(response.data.validatorerror.Type);
  setparticular_er(response.data.validatorerror.Particular);
  //setamount_er(response.data.validatorerror.Amount);
  setbank_id_er(response.data.validatorerror.Bank_id);
  setattachment_link_er(response.data.validatorerror.File_attachment);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

  setdate_er(response.data.date_er);
  settype_er(response.data.type_er);
  setbank_id_er(response.data.bank_id_er);
  setparticular_er(response.data.particular_er);
  //setamount_er(response.data.amount_er);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Comlete Transaction!"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE TRANSACTION FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Part Payment Request Sent Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/employee-dashboard-customer-emis"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-loan-closure-requests")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">PART PAYMENT</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Part Payment Request</h4>
                    <div class="form-sample">
                      <p class="card-description">create new part payment request</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Loan Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={loan_number}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Repayable Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={loan_amount}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Paid Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={loan_amount-remaining_amount}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Remaining Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={remaining_amount}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total EMIs</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={duration}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMI</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={emi}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMIs Paid</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={emis_paid}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMIs Due</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={emis_due}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Part Pay EMIs</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={pay_emis} id="pay_emis" placeholder="5" onChange={updatepay_emis}  />
                              <code>{pay_emis_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={amount} id="amount" placeholder="5000.00" /*onChange={updateamount}*/  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype}>
                            <option value="">- select mode --</option>
                            <option value="cash">cash</option>
                            <option value="bank">bank</option>
                            </select>
                            <code>{type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea  class="form-control" rows="2" placeholder="here particular" id="particular" value={particular} onChange={updateparticular} />
                        <code>{particular_er}</code>
                      </div>

                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                            <option value=""> -- select bank -- </option>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                            <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                            </select>
                            <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div> 
                    
                        </div>

                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attachment</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{attachment_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={pay_partpayment}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_part_payment;