import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';



function Admin_add_bank(){

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");


 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


useEffect(()=>{
   
    getData();
    
    },[])
   

  //SETTING DATA VARIABLES
  const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
  const[account_number,setaccount_number]=useState();const updateaccount_number = (event) => { setaccount_number(event.target.value);}; const[account_number_er,setaccount_number_er]=useState();
  const[ifsc_code,setifsc_code]=useState();const updateifsc_code = (event) => { setifsc_code(event.target.value);}; const[ifsc_code_er,setifsc_code_er]=useState();
  const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();

  
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();



 

//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
   
   axios.post("crm-admin-get-banks-list", {
     Log_id:logid,
     Token:token,
     
     //Code Edits for pagination
     'page' : pageNumber,
  
     //Filters
     'Filter_val' :filterval,
  
  
     })
   .then((response) => {
  
  
    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("logName00");
  
       window.location.replace("/");
    }
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setData(response.data.data.data);
    }
  
    //FILTER DATA
    //Validation Errors
    if(response.data.validatorerror){
  
      seterrorvar("Validation error!");
  
    }
  
    //Data Violation
    if(response.data.error==102){ 
    
       //Setting Filter Violation Errors
       seterrorvar("Violation Error");
  
  
    }
  
  
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
  
     window.location.replace("/");
   
    }
   
   }
    
   //******************* GET BANKS DATA FUNCTION END  ********************//
 
 
/***************** ADD BANK FUNCTION START  ******************/
function add_bank(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setbank_name_er(" ");setifsc_code_er(" ");setaccount_number_er(" ");settype_er(" ");seterror_msg(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $bank_name = document.getElementById("bank_name").value; if($bank_name==""){ setbank_name_er("Bank Name is required !");noerror = noerror+1; }//bank_name
const $type = document.getElementById("type").value;//type 
const $ifsc_code = document.getElementById("ifsc_code").value; //ifsc_code
const $account_number = document.getElementById("account_number").value; //account_number

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-admin-add-new-bank", { 
  Log_id:logid,
  Token:token,
  Bank_name:$bank_name,
  Type:$type,
  Ifsc_code:$ifsc_code,
  Account_number:$account_number
 
  })
  
  .then((response) => {

  //Validation Errors
  if(response.data.validatorerror){
  setbank_name_er(response.data.validatorerror.Bank_name);
  settype_er(response.data.validatorerror.Type);
  setifsc_code_er(response.data.validatorerror.Ifsc_code);
  setaccount_number_er(response.data.validatorerror.Account_number);
  seterror_msg("Cannot Add Bank !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

  setbank_name_er(response.data.bank_name_er);
  settype_er(response.data.type_er);
  setifsc_code_er(response.data.ifsc_code_er);
  setaccount_number_er(response.data.account_number_er);
  seterror_msg("Cannot Add Bank !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Add Bank !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** ADD BANK FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Bank Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="adminpanel-dashboard-bank"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/adminpanel-dashboard-bank")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">ADD BANK</p>
                  </a>
                  
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Add New Bank</h4>
                    <div class="form-sample">
                      <p class="card-description">add new bank</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="SBI Bank" id="bank_name" value={bank_name} onChange={updatebank_name} />
                              <code>{bank_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IFSC Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="SBI0000001" id="ifsc_code" value={ifsc_code} onChange={updateifsc_code} />
                              <code>{ifsc_code_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="current" id="type" value={type} onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>  
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="12121212112222" id="account_number" value={account_number} onChange={updateaccount_number} />
                              <code>{account_number_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>

                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={add_bank}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>


                
              {/* BANKS TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Banks</h4>
                    <p className="card-description">
                    </p>

                    <div className="table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                            <th style={{fontWeight:"bold"}}>Bank Id</th>
                            <th style={{fontWeight:"bold"}}>Bank Name</th>
                            <th style={{fontWeight:"bold"}}>IFSC Code</th>
                            <th style={{fontWeight:"bold"}}>Type</th>
                            <th style={{fontWeight:"bold"}}>Account Number</th>
                            <th style={{fontWeight:"bold"}}>Status</th>
                            <th style={{fontWeight:"bold"}}>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                        <tr>
                          <td>{curElm.Bank_id}</td>
                          <td>{curElm.Bank_name}</td>
                          <td>{curElm.Ifsc_code}</td>
                          <td>{curElm.Bank_account_type}</td>
                          <td>{curElm.Account_number}</td>
                          <td><label className="badge badge-success" hidden={curElm.Bank_status=="inactive"}>active</label>
                          <label className="badge badge-danger" hidden={curElm.Bank_status=="active"}>inactive</label>
                          </td>
                          <td>{curElm.Date_updated} ({curElm.Time_updated})</td>
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                         
                        </tbody>
                      </table>
                    </div>
                    
                    {/* PAGINATION SECTION START */}
                    <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>

            {/* BANKS TABLE SECTION END */}

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_add_bank;