import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Employee_loan_noc(){


  //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  
    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");

 
 //SETTING DATA VARIABLES
 const[loan_no,setloan_no]=useState();
 const[customer_id,setcustomer_id]=useState();
 const[branch_code,setbranch_code]=useState();
 const[account_number,setaccount_number]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[loan_amount,setloan_amount]=useState();
 const[interest_rate,setinterest_rate]=useState();
 const[repayable_amount,setrepayable_amount]=useState();
 const[remaining_amount,setremaining_amount]=useState();
 const[closure_per,setclosure_per]=useState();
 const[closure_charge,setclosure_charge]=useState();
 const[closure_status,setclosure_status]=useState();
 const[mode,setmode]=useState();
 const[bank_id,setbank_id]=useState();
 const[bank_name,setbank_name]=useState();
 const[date,setdate]=useState();
 const[payment_date,setpayment_date]=useState();
 const[approval_date,setapproval_date]=useState();
 const[action,setaction]=useState();
 const[group_name,setgroup_name]=useState();
 const[coapplicant_name,setcoapplicant_name]=useState();
 const[completed_date,setcompleted_date]=useState();
 const[disbursal_date,setdisbursal_date]=useState();

 
//Header
const[haddress,sethaddress]=useState(sessionStorage.getItem("headerAddress00"));const updatehaddress = (event) => { sethaddress(event.target.value);}; const[haddress_er,sethaddress_er]=useState();
const[hcity,sethcity]=useState(sessionStorage.getItem("headerCity00"));const updatehcity = (event) => { sethcity(event.target.value);}; const[hcity_er,sethcity_er]=useState();
const[hstate,sethstate]=useState(sessionStorage.getItem("headerState00"));const updatehstate = (event) => { sethstate(event.target.value);}; const[hstate_er,sethstate_er]=useState();
const[hpincode,sethpincode]=useState(sessionStorage.getItem("headerPincode00"));const updatehpincode = (event) => { sethpincode(event.target.value);}; const[hpincode_er,sethpincode_er]=useState();
const[hemail,sethemail]=useState(sessionStorage.getItem("headerEmail00"));const updatehemail = (event) => { sethemail(event.target.value);}; const[hemail_er,sethemail_er]=useState();
const[hcontact,sethcontact]=useState(sessionStorage.getItem("headerContact00"));const updatehcontact = (event) => { sethcontact(event.target.value);}; const[hcontact_er,sethcontact_er]=useState();


  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  useEffect(()=>{
   
    getData();
    
    },[])  
   
  
  //******************* VIEW LOAN NOC FUNCTION START  ********************//
  function getData(){
   
  
    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
   
   axios.post("crm-employee-view-noc-certificate", {
     Log_id:logid,
     Token:token,
     Edit_id:editId,
    // Branch_id:branch_id
  
  
     })
   .then((response) => {
  
    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("logName00");
       sessionStorage.removeItem("EditId00");
       window.location.replace("/");
    }
  
    //Data Not Found
    if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}
  
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setloan_no(response.data.loan_no);
     setcustomer_id(response.data.customer_id);
     setaccount_number(response.data.account_number);
     setbranch_code(response.data.branch_code);
     setaccount_number(response.data.account_number);
     setfirst_name(response.data.first_name);
     setlast_name(response.data.last_name);
     setcontact(response.data.contact);
     setemail(response.data.email);
     setscheme_code(response.data.scheme_code);
     setscheme_name(response.data.scheme_name);
     setloan_amount(response.data.loan_amount);
     setinterest_rate(response.data.interest_rate);
     setrepayable_amount(response.data.repayable_amount);
     setremaining_amount(response.data.remaining_amount);
     setclosure_per(response.data.closure_per);
     setclosure_charge(response.data.closure_charge);
     setclosure_status(response.data.closure_status);
     setmode(response.data.mode);
     setbank_id(response.data.bank_id);
     setbank_name(response.data.bank_name);
     setdate(response.data.date);
     setpayment_date(response.data.payment_date);
     setapproval_date(response.data.approval_date);
     setcoapplicant_name(response.data.coapplicant_name);
     setcompleted_date(response.data.completed_date);
     setgroup_name(response.data.group_name);
     setdisbursal_date(response.data.disbursal_date);
  
    }
  
    //FILTER DATA
    //Validation Errors
    if(response.data.validatorerror){ seterror_msg("Validation error!"); }
  
    //Data Violation
    if(response.data.error==102){  seterror_msg("Violation Error"); }
  
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/"); 
     //console.log(response.data);
   
    }
   
   } 
    
   //******************* VIEW LOAN loan FUNCTION END  ********************//
  
  
  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

           

          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-customer-loans")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">LOAN NOC</p>
                  </a>
                </div>
             
                </div>
                </div>

            <button type="button" style={{backgroundColor:"#196fb0", marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
            </button>
            <br/><br/>

                <div class="card">

                 {/* Print Section Start */}
                 <div class="" style={{padding:"1rem"}}  id="invoice" ref={componentRef}>

                  {/* LOAN CLOSURE REQUEST HEADER START */}
                  <div class="offer-letter-head" style={{borderBottom:"0.2rem solid #038d8e"}}>
                  <div style={{flexDirection:"row", display:"flex"}}>
                  <div style={{width:"25%"}} >
                    <img src="images/crmlogo.png" alt="profile" style={{width:"16rem", height:"16rem"}} />
                  </div>
                  <div class="row" style={{width:"75%"}}>

                    <div class="row col-md-12">
                    <h1 style={{color:"#038d8e", marginBottom:"0", textAlign:"center"}}><b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b></h1>
                    
                    <p style={{marginTop:"0", textAlign:"center"}}><b>Address:</b> {haddress} <br/>
                    {hcity} ({hstate}), ({hpincode}) <br/><br/>
                    <b>Email:</b> {hemail}, <b>Contact:</b> {hcontact}</p>

                  
                    </div>
                    </div>
                    </div>
                    </div>
                    
                    {/* LOAN CLOSURE REQUEST HEADER END */}
                    
                    <h2 style={{textAlign:"center", marginTop:"2rem"}}>LOAN CLOSURE CERTIFICATE (NO DUES)</h2>
                    <hr/>
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h3 class="card-title">THIS IS TO CERTIFY THAT LOAN NO. <b>{loan_no}</b> TAKEN BY <b>{first_name} {last_name}</b> IS CLOSED IN OUR BOOKS AND BALANCE SHEET.</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    <br/><br/><br/>
                    
                    <div class="row" style={{textAlign:"left"}}>
                   
                    <div class="row col-md-12">
                    <div class="row col-sm-12"> <p><b>LOAN DETAILS AS ON {completed_date}</b></p></div>
                    {/*<div class="row col-sm-6"> <p>{customer_id}</p></div>*/}
                    </div>
                    <br/><br/><br/><br/><br/>
                 {/*}   <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Customer Name:</b></p></div>
                    <div class="row col-sm-6"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6"> <p>{contact}</p></div>
                    </div> */}
                    </div>
                    <div class="row d-flex justify-content-center">
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>BRANCH CODE:</b></p></div>
                    <div class="row col-sm-6"> <p>{branch_code}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>CUSTOMER ID:</b></p></div>
                    <div class="row col-sm-6"> <p>{customer_id}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>ACCOUNT NUMBER:</b></p></div>
                    <div class="row col-sm-6"> <p>{account_number}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>CUSTOMER NAME:</b></p></div>
                    <div class="row col-sm-6"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>GROUP NAME:</b></p></div>
                    <div class="row col-sm-6"> <p>{group_name}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>COAPPLICANT NAME:</b></p></div>
                    <div class="row col-sm-6"> <p>{coapplicant_name}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>LOAN NO.:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_no}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>LOAN DATE:</b></p></div>
                    <div class="row col-sm-6"> <p>{disbursal_date}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>LOAN AMOUNT:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_amount}</p></div>
                    </div>
                    <div class="row col-md-8">
                    <div class="row col-sm-6"> <p><b>CLOSURE/ COMPLETION DATE:</b></p></div>
                    <div class="row col-sm-6"> <p>{completed_date}</p></div>
                    </div>
                


                    <br/><br/><br/><br/><br/>
                      
                     


              
              
                    <div class="row col-md-8">
                   
                    </div>
                    <div class="row col-md-4">
                 {/*}   <div class="row col-sm-12"> <p class="text-primary"><b>seal and sign.</b></p></div> */}
                    <br/><br/><br/>{/*<br/><br/><br/><br/><br/><br/>*/}
                    <div class="row col-sm-12">
                    <img src="images/comseal.png" alt="profile" style={{width:"18rem", height:"10rem"}} /></div>
                    <div class="row col-sm-12"><img src="images/comsign.png" alt="profile" style={{width:"18rem", height:"6rem"}} />
                    </div>  
                    <div class="row col-sm-12"> <p><b>MR. DINESH DWIVEDI</b></p></div>
                    <div class="row col-sm-12"> <p><b>(VICE PRESIDENT)</b></p></div>
                    </div>
                  

                  </div>
                    </div>
                    </div>
                   
                    </div>
                    </div>
                    

                 
              
                

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_loan_noc;