import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';



function Admin_create_loanscheme(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");


 //SETTING DATA VARIABLES
 //scheme_name
 const[scheme_name,setscheme_name]=useState();const updatescheme_name = (event) => { setscheme_name(event.target.value);}; const[scheme_name_er,setscheme_name_er]=useState();
 const[scheme_code,setscheme_code]=useState();const updatescheme_code = (event) => { setscheme_code(event.target.value);}; const[scheme_code_er,setscheme_code_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
 const[loan_amount,setloan_amount]=useState();const updateloan_amount = (event) => { setloan_amount(event.target.value); calculate(); }; const[loan_amount_er,setloan_amount_er]=useState();
 const[interest_rate,setinterest_rate]=useState();const updateinterest_rate = (event) => { setinterest_rate(event.target.value);}; const[interest_rate_er,setinterest_rate_er]=useState();
 const[pf_per,setpf_per]=useState();const updatepf_per = (event) => { setpf_per(event.target.value); calculate(); }; const[pf_per_er,setpf_per_er]=useState();
 const[pf_amount,setpf_amount]=useState();const updatepf_amount = (event) => { setpf_amount(event.target.value); calculate(); }; const[pf_amount_er,setpf_amount_er]=useState();
 const[death_insurance,setdeath_insurance]=useState();const updatedeath_insurance = (event) => { setdeath_insurance(event.target.value); calculate(); }; const[death_insurance_er,setdeath_insurance_er]=useState();
 const[health_insurance,sethealth_insurance]=useState();const updatehealth_insurance = (event) => { sethealth_insurance(event.target.value); calculate(); }; const[health_insurance_er,sethealth_insurance_er]=useState();
 const[insurance_gst,setinsurance_gst]=useState();const updateinsurance_gst = (event) => { setinsurance_gst(event.target.value); calculate(); }; const[insurance_gst_er,setinsurance_gst_er]=useState();
 const[gst_per,setgst_per]=useState();const updategst_per = (event) => { setgst_per(event.target.value); calculate(); }; const[gst_per_er,setgst_per_er]=useState();
 const[gst_amount,setgst_amount]=useState();const updategst_amount = (event) => { setgst_amount(event.target.value); calculate(); }; const[gst_amount_er,setgst_amount_er]=useState();
 const[total_additions,settotal_additions]=useState();const updatetotal_additions = (event) => { settotal_additions(event.target.value); calculate(); }; const[total_additions_er,settotal_additions_er]=useState();
 const[loan_interest,setloan_interest]=useState();const updateloan_interest = (event) => { setloan_interest(event.target.value); calculate(); }; const[loan_interest_er,setloan_interest_er]=useState();
 const[loan_int_gst,setloan_int_gst]=useState();const updateloan_int_gst = (event) => { setloan_int_gst(event.target.value); calculate(); }; const[loan_int_gst_er,setloan_int_gst_er]=useState();
 const[duration,setduration]=useState();const updateduration = (event) => { setduration(event.target.value); calculate(); }; const[duration_er,setduration_er]=useState();
 const[minimum_monthly_income,setminimum_monthly_income]=useState();const updateminimum_monthly_income = (event) => { setminimum_monthly_income(event.target.value);}; const[minimum_monthly_income_er,setminimum_monthly_income_er]=useState();
 const[repayable_amount,setrepayable_amount]=useState();const updaterepayable_amount = (event) => { setrepayable_amount(event.target.value); calculate(); }; const[repayable_amount_er,setrepayable_amount_er]=useState();
 const[emi,setemi]=useState();const updateemi = (event) => { setemi(event.target.value); calculate(); }; const[emi_er,setemi_er]=useState();
// const[processing_fee,setprocessing_fee]=useState();const updateprocessing_fee = (event) => { setprocessing_fee(event.target.value); calculate(); }; const[processing_fee_er,setprocessing_fee_er]=useState();
 const[emi_principle,setemi_principle]=useState();const updateemi_principle = (event) => { setemi_principle(event.target.value); calculate(); }; const[emi_principle_er,setemi_principle_er]=useState();
 const[emi_interest,setemi_interest]=useState();const updateemi_interest = (event) => { setemi_interest(event.target.value); calculate(); }; const[emi_interest_er,setemi_interest_er]=useState();
 const[emi_gst,setemi_gst]=useState();const updateemi_gst = (event) => { setemi_gst(event.target.value); calculate(); }; const[emi_gst_er,setemi_gst_er]=useState();
 const[details,setdetails]=useState();const updatedetails = (event) => { setdetails(event.target.value);}; const[details_er,setdetails_er]=useState();
 const[scheme_document_link,setscheme_document_link]=useState();const updatescheme_document_link = (event) => { setscheme_document_link(event.target.value);}; const[scheme_document_link_er,setscheme_document_link_er]=useState();

 
 //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();


  
  
function calculate(){
  
  //Fetching Values
  let loan_amount = (document.getElementById("loan_amount").value);if(loan_amount==" "){ loan_amount=0;}else{ loan_amount=parseFloat(loan_amount);}
  let interest_rate = (document.getElementById("interest_rate").value);if( interest_rate==" "){ interest_rate=0;}else{ interest_rate=parseFloat(interest_rate);}
  let pf_per = (document.getElementById("pf_per").value);if( pf_per==" "){ pf_per=0;}else{ pf_per=parseFloat(pf_per);}
  let pf_amount = (document.getElementById("pf_amount").value);if(pf_amount==" "){ pf_amount=0;}else{ pf_amount=parseFloat(pf_amount);}
  let gst_per = (document.getElementById("gst_per").value);if( gst_per==" "){ gst_per=0;}else{ gst_per=parseFloat(gst_per);}
  //let gst_amount = (document.getElementById("gst_amount").value);if(gst_amount==" "){ gst_amount=0;}else{ gst_amount=parseFloat(gst_amount);}
  let insurance_gst = (document.getElementById("insurance_gst").value);if(insurance_gst==" "){ insurance_gst=0;}else{ insurance_gst=parseFloat(insurance_gst);}
  let death_insurance = (document.getElementById("death_insurance").value);if( death_insurance==" "){ death_insurance=0;}else{ death_insurance=parseFloat(death_insurance);}
  let health_insurance = (document.getElementById("health_insurance").value);if(health_insurance==" "){ health_insurance=0;}else{ health_insurance=parseFloat(health_insurance);}
  let total_additions = (document.getElementById("total_additions").value);if( total_additions==" "){ total_additions=0;}else{ total_additions=parseFloat(total_additions);}
  let loan_interest = (document.getElementById("loan_interest").value);if(loan_interest==" "){ loan_interest=0;}else{ loan_interest=parseFloat(loan_interest);}
  let repayable_amount = (document.getElementById("repayable_amount").value);if(repayable_amount==" "){ repayable_amount=0;}else{ repayable_amount=parseFloat(repayable_amount);}
  let emi = (document.getElementById("emi").value);if(emi==" "){ emi=0;}else{ emi=parseFloat(emi);}
//  let processing_fee = (document.getElementById("processing_fee").value);if(processing_fee==" "){ processing_fee=0;}else{ processing_fee=parseFloat(processing_fee);}

  //Interest Calculation
 if(interest_rate>0){ loan_interest = loan_amount*interest_rate/100; }
 //PF Calculation
 if(pf_per>0){ pf_amount = loan_amount*pf_per/100; }

 let pre_additions = loan_interest+pf_amount+death_insurance+health_insurance;
 //if(gst_per>0){ gst_amount = loan_amount*gst_per/100; } //calculating gst on insurance instead of loan_amount
 let insurance_total = death_insurance+health_insurance;
 let inst_total = insurance_total + loan_interest;
// if(gst_per>0){ gst_amount = insurance_total*gst_per/100; }
//if(gst_per>0){ gst_amount = inst_total*gst_per/100; }
if(gst_per>0){ insurance_gst = insurance_total*gst_per/100; }

 //gst_amount = insurance_gst+loan_int_gst;
 //repayable_amount = loan_amount+total_additions;
 //repayable_amount = loan_amount+loan_interest;
 let loan_int_gst = loan_interest*0.18;
 repayable_amount = loan_amount+loan_interest+loan_int_gst;
 let gst_amount = insurance_gst+loan_int_gst;
 total_additions = pre_additions+gst_amount; //+processing_fee;

 //emi = repayable_amount/duration;
 emi = parseFloat(repayable_amount/duration).toFixed();
 let emi_principle = parseFloat(loan_amount/duration).toFixed();
 let emi_interest = parseFloat(loan_interest/duration).toFixed();
 let emi_gst = parseFloat(loan_int_gst/duration).toFixed();

setinsurance_gst(insurance_gst);
setloan_interest(loan_interest);
setloan_int_gst(loan_int_gst);
setgst_amount(gst_amount);
setpf_amount(pf_amount);
settotal_additions(total_additions);
setrepayable_amount(repayable_amount);
setemi(emi);
setemi_principle(emi_principle);
setemi_interest(emi_interest);
setemi_gst(emi_gst);
 

}

  
/***************** CREATE NEW LOAN SCHEME FUNCTION START  ******************/
function create_loanscheme(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setscheme_name_er(" ");setscheme_code_er(" ");settype_er(" "); setloan_amount_er(" ");setinterest_rate_er(" ");setpf_per_er(" ");setpf_amount_er(" ");
    setdeath_insurance_er(" ");sethealth_insurance_er(" ");setgst_per_er(" ");setgst_amount_er(" ");settotal_additions_er(" ");setloan_interest_er(" ");setduration_er(" ");
    setminimum_monthly_income_er(" ");setrepayable_amount_er(" ");setemi_er(" ");setdetails_er(" ");setscheme_document_link_er(" ");seterror_msg(" ");
    setinsurance_gst_er(" ");setloan_int_gst_er(" ");setemi_principle_er(" ");setemi_interest_er(" ");setemi_gst_er(" ");
    
//Fetching Form Data
let noerror = 0;
const $scheme_name = document.getElementById("scheme_name").value; if($scheme_name==""){ setscheme_name_er("Scheme Name is required !");noerror = noerror+1; }//scheme_name
const $scheme_code = document.getElementById("scheme_code").value; if($scheme_code==""){ setscheme_code_er("Scheme Code is required !");noerror = noerror+1; }//scheme_code
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//type
const $loan_amount = document.getElementById("loan_amount").value; if($loan_amount==""){ setloan_amount_er("Loan Amount is required !");noerror = noerror+1; }//loan_amount
const $interest_rate = document.getElementById("interest_rate").value; if($interest_rate==""){ setinterest_rate_er("Interest Rate is required !");noerror = noerror+1; }//interest_rate
const $pf_per = document.getElementById("pf_per").value; if($pf_per==""){ setpf_per_er("PF % is required !");noerror = noerror+1; }//pf_per
const $pf_amount = document.getElementById("pf_amount").value; if($pf_amount==""){ setpf_amount_er(" PF Amount is required !");noerror = noerror+1; }//pf_amount
const $death_insurance = document.getElementById("death_insurance").value; if($death_insurance==""){ setdeath_insurance_er("Death Insurance is required !");noerror = noerror+1; }//death_insurance
const $health_insurance = document.getElementById("health_insurance").value; if($health_insurance==""){ sethealth_insurance_er("Health Insurance is required !");noerror = noerror+1; }//health_insurance
const $insurance_gst = document.getElementById("insurance_gst").value; if($insurance_gst==""){ setinsurance_gst_er("Insurance GST is required !");noerror = noerror+1; }//insurance_gst
const $gst_per = document.getElementById("gst_per").value; if($gst_per==""){ setgst_per_er("GST % is required !");noerror = noerror+1; }//gst_per
const $gst_amount = document.getElementById("gst_amount").value; if($gst_amount==""){ setgst_amount_er("GST Amount is required !");noerror = noerror+1; }//gst_amount
const $total_additions = document.getElementById("total_additions").value; if($total_additions==""){ settotal_additions_er("Total Additions is required !");noerror = noerror+1; }//total_additions
const $loan_interest = document.getElementById("loan_interest").value; if($loan_interest==""){ setloan_interest_er("Loan Interest is required !");noerror = noerror+1; }//loan_interest
const $loan_int_gst = document.getElementById("loan_int_gst").value; if($loan_int_gst==""){ setloan_int_gst_er("GST on Interest is required !");noerror = noerror+1; }//loan_int_gst
const $duration = document.getElementById("duration").value; if($duration==""){ setduration_er("Duration is required !");noerror = noerror+1; }//duration
const $minimum_monthly_income = document.getElementById("minimum_monthly_income").value; if($minimum_monthly_income==""){ setminimum_monthly_income_er("Minimum Monthly Income is required !");noerror = noerror+1; }//minimum_monthly_income
const $repayable_amount = document.getElementById("repayable_amount").value; if($repayable_amount==""){ setrepayable_amount_er("Repayable Amount is required !");noerror = noerror+1; }//repayable_amount
const $emi = document.getElementById("emi").value; if($emi==""){ setemi_er("EMI is required !");noerror = noerror+1; }//emi
const $emi_principle = document.getElementById("emi_principle").value; if($emi_principle==""){ setemi_principle_er("EMI Principle is required !");noerror = noerror+1; }//emi_principle
const $emi_interest = document.getElementById("emi_interest").value; if($emi_interest==""){ setemi_interest_er("EMI Interest is required !");noerror = noerror+1; }//emi_interest
const $emi_gst = document.getElementById("emi_gst").value; if($emi_gst==""){ setemi_gst_er("EMI GST is required !");noerror = noerror+1; }//emi_gst
const $details = document.getElementById("details").value; if($details==""){ setdetails_er("Details is required !");noerror = noerror+1; }//details
const $scheme_document_link = document.getElementById("scheme_document_link").value; if($scheme_document_link==""){ setscheme_document_link_er("Scheme Document Link is required !");noerror = noerror+1; }//scheme_document_link
//const $processing_fee = document.getElementById("processing_fee").value; if($processing_fee==""){ setprocessing_fee_er("Processing Fee is required !");noerror = noerror+1; }//processing_fee



//No primary errors
if(noerror==0){

//  const $calculatedgst = $gst_amount+$loan_int_gst;

  //Sending Data through axios
  axios.post("crm-create-new-loan-scheme", { 
  Log_id:logid,
  Token:token,
  Scheme_name:$scheme_name,
  Scheme_code:$scheme_code,
  Type:$type,
  Loan_amount:$loan_amount,
  Interest_rate:$interest_rate,
  PF_per:$pf_per,
  PF_amount:$pf_amount,
  Death_insurance:$death_insurance,
  Health_insurance:$health_insurance,
  GST_per:$gst_per,
  GST_amount:$gst_amount,
  Total_additions:$total_additions,
  Loan_interest:$loan_interest,
  Processing_fee:$pf_amount,//processing_fee
  Duration:$duration,
  Minimum_monthly_income:$minimum_monthly_income,
  Repayable_amount:$repayable_amount,
  EMI:$emi,
  Details:$details,
  Scheme_document_link:$scheme_document_link,
  Insurance_gst:$insurance_gst,
  Interest_gst:$loan_int_gst,
  Emi_principle:$emi_principle,
  Emi_interest:$emi_interest,
  Emi_gst: $emi_gst


  }
    )
  
  .then((response) => {
  
  //Validation Errors
  if(response.data.validatorerror){
  setscheme_name_er(response.data.validatorerror.Scheme_name);
  setscheme_code_er(response.data.validatorerror.Scheme_code);
  settype_er(response.data.validatorerror.Type);
  setloan_amount_er(response.data.validatorerror.Loan_amount);
  setinterest_rate_er(response.data.validatorerror.Interest_rate);
  setpf_per_er(response.data.validatorerror.PF_per);
  setpf_amount_er(response.data.validatorerror.PF_amount);
  setdeath_insurance_er(response.data.validatorerror.Death_insurance);
  sethealth_insurance_er(response.data.validatorerror.Health_insurance);
  setgst_per_er(response.data.validatorerror.GST_per);
  setgst_amount_er(response.data.validatorerror.GST_amount);
  settotal_additions_er(response.data.validatorerror.Total_additions);
 // setprocessing_fee_er(response.data.validatorerror.Processing_fee);
  setloan_interest_er(response.data.validatorerror.Loan_interest);
  setduration_er(response.data.validatorerror.Duration);
  setminimum_monthly_income_er(response.data.validatorerror.Minimum_monthly_income);
  setrepayable_amount_er(response.data.validatorerror.Repayable_amount);
  setemi_er(response.data.validatorerror.EMI);
  setdetails_er(response.data.validatorerror.Details);
  setscheme_document_link_er(response.data.validatorerror.Scheme_document_link);
  setinsurance_gst_er(response.data.validatorerror.Insurance_gst);
  setloan_int_gst_er(response.data.validatorerror.Interest_gst);
  setemi_principle_er(response.data.validatorerror.Emi_principle);
  setemi_interest_er(response.data.validatorerror.Emi_interest);
  setemi_gst_er(response.data.validatorerror.Emi_gst);
  seterror_msg("Cannot Create Loan Scheme !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    setscheme_name_er(response.data.scheme_name_er);
    setscheme_code_er(response.data.scheme_code_er);
    settype_er(response.data.Type_er);
    setloan_amount_er(response.data.loan_amount_er);
    setinterest_rate_er(response.data.interest_rate_er);
    setpf_per_er(response.data.pf_per_er);
    setpf_amount_er(response.data.pf_amount_er);
    setdeath_insurance_er(response.data.death_insurance_er);
    sethealth_insurance_er(response.data.health_insurance_er);
    setgst_per_er(response.data.gst_per_er);
    setgst_amount_er(response.data.gst_amount_er);
    settotal_additions_er(response.data.total_additions_er);
   // setprocessing_fee_er(response.data.processing_fee_er);
    setloan_interest_er(response.data.loan_interest_er);
    setduration_er(response.data.duration_er);
    setminimum_monthly_income_er(response.data.minimum_monthly_income_er);
    setrepayable_amount_er(response.data.repayable_amount_er);
    setemi_er(response.data.emi_er);
    setdetails_er(response.data.details_er);
    setscheme_document_link_er(response.data.scheme_document_link_er);
    setinsurance_gst_er(response.data.insurance_gst_er);
    setloan_int_gst_er(response.data.interest_gst_er);
    setemi_principle_er(response.data.emi_principle_er);
    setemi_interest_er(response.data.emi_interest_er);
    setemi_gst_er(response.data.emi_gst_er);
    seterror_msg("Cannot Create Loan Scheme !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Loan Scheme Not Created !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE NEW LOAN SCHEME FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Loan Scheme Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/adminpanel-dashboard-loan-schemes"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/adminpanel-dashboard-loan-schemes")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CREATE LOAN SCHEME</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Loan Scheme</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LS1234567" id="scheme_code" value={scheme_code} onChange={updatescheme_code} />
                              <code>{scheme_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular Rural Loan" id="scheme_name" value={scheme_name} onChange={updatescheme_name} />
                              <code>{scheme_name_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Loan Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="24000.00" id="loan_amount" value={loan_amount} onChange={updateloan_amount} />
                              <code>{loan_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest Rate<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="20.00" id="interest_rate" value={interest_rate} onChange={updateinterest_rate} />
                              <code>{interest_rate_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">PF %<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2.00" id="pf_per" value={pf_per} onChange={updatepf_per} />
                              <code>{pf_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">PF Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="480.00" id="pf_amount" value={pf_amount} onChange={updateloan_amount} />
                              <code>{pf_amount_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Death Insurance<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="20" id="death_insurance" value={death_insurance} onChange={updatedeath_insurance} />
                              <code>{death_insurance_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Health Insurance<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="200.00" id="health_insurance" value={health_insurance} onChange={updatehealth_insurance} />
                              <code>{health_insurance_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GST %<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="18.00" id="gst_per" value={gst_per} onChange={updategst_per} />
                              <code>{gst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GST on Insurance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2000.00" id="insurance_gst" value={insurance_gst} onChange={updateinsurance_gst} />
                              <code>{insurance_gst_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Additions to Loan Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2600.00" id="total_additions" value={total_additions} onChange={updatetotal_additions} />
                              <code>{total_additions_er}</code>
                            </div>
                          </div>
                        </div>
                     {/*}   <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Processing Fee<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2600.00" id="processing_fee" value={processing_fee} onChange={updateprocessing_fee} />
                              <code>{processing_fee_er}</code>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest For Tenure</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="21000.00" id="loan_interest" value={loan_interest} onChange={updateloan_interest} />
                              <code>{loan_interest_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GST @ 18% on Interest</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500" id="loan_int_gst" value={loan_int_gst} onChange={updateloan_int_gst} />
                              <code>{loan_int_gst_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration (in weeks)<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="26" id="duration" value={duration} onChange={updateduration} />
                              <code>{duration_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total GST</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2000.00" id="gst_amount" value={gst_amount} onChange={updategst_amount} />
                              <code>{gst_amount_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Repayable Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="32500.00" id="repayable_amount" value={repayable_amount} onChange={updaterepayable_amount} />
                              <code>{repayable_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMI</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2200.00" id="emi" value={emi} onChange={updateemi} />
                              <code>{emi_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMI Principle</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2000.00" id="emi_principle" value={emi_principle} onChange={updateemi_principle} />
                              <code>{emi_principle_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMI Interest</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="200.00" id="emi_interest" value={emi_interest} onChange={updateemi_interest} />
                              <code>{emi_interest_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMI GST</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="20.00" id="emi_gst" value={emi_gst} onChange={updateemi_gst} />
                              <code>{emi_gst_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Minimum Monthly Income<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="20000.00" id="minimum_monthly_income" value={minimum_monthly_income} onChange={updateminimum_monthly_income} />
                              <code>{minimum_monthly_income_er}</code>
                            </div>
                          </div>
                        </div> 
                      
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="regular" id="type" value={type} onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Document Link<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="https://myschemelink/...." id="scheme_document_link" value={scheme_document_link} onChange={updatescheme_document_link} />
                              <code>{scheme_document_link_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                      
                       
                        <div class="form-group">
                        <label for="exampleTextarea1">Details<code>*</code></label>
                        <textarea  class="form-control" rows="2" placeholder="here details" id="details" value={details} onChange={updatedetails} />
                        <code>{details_er}</code>
                        </div>

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={create_loanscheme}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_create_loanscheme;