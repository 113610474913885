import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';


function Admin_create_offer_letter(){

  //const imgpath = 'http://127.0.0.1:8000/storage/';
  const imgpath = 'https://pragatiprathmik.com/api/';
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
  

 //SETTING DATA VARIABLES
 const[date,setdate]=useState(); const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
 const[offer_id,setoffer_id]=useState(); const updateoffer_id = (event) => { setoffer_id(event.target.value);}; const[offer_id_er,setoffer_id_er]=useState();
 const[employee_code,setemployee_code]=useState(); const updateemployee_code = (event) => { setemployee_code(event.target.value);}; const[employee_code_er,setemployee_code_er]=useState();
 const[employee_name,setemployee_name]=useState(); const updateemployee_name = (event) => { setemployee_name(event.target.value);}; const[employee_name_er,setemployee_name_er]=useState();
 const[working_city,setworking_city]=useState(); const updateworking_city = (event) => { setworking_city(event.target.value);}; const[working_city_er,setworking_city_er]=useState();
 const[working_state,setworking_state]=useState(); const updateworking_state = (event) => { setworking_state(event.target.value);}; const[working_state_er,setworking_state_er]=useState();
 const[date_of_joining,setdate_of_joining]=useState(); const updatedate_of_joining = (event) => { setdate_of_joining(event.target.value);}; const[date_of_joining_er,setdate_of_joining_er]=useState();
 const[join_before,setjoin_before]=useState(); const updatejoin_before = (event) => { setjoin_before(event.target.value);}; const[join_before_er,setjoin_before_er]=useState();
 const[department,setdepartment]=useState(); const updatedepartment = (event) => { setdepartment(event.target.value);}; const[department_er,setdepartment_er]=useState();
 const[designation,setdesignation]=useState(); const updatedesignation = (event) => { setdesignation(event.target.value);}; const[designation_er,setdesignation_er]=useState();
 const[reporting_manager,setreporting_manager]=useState(); const updatereporting_manager = (event) => { setreporting_manager(event.target.value);}; const[reporting_manager_er,setreporting_manager_er]=useState();
 const[gross_salary,setgross_salary]=useState(0); const updategross_salary = (event) => { setgross_salary(event.target.value);}; const[gross_salary_er,setgross_salary_er]=useState();
 const[gross_salary_inwords,setgross_salary_inwords]=useState(); const updategross_salary_inwords = (event) => { setgross_salary_inwords(event.target.value);}; const[gross_salary_inwords_er,setgross_salary_inwords_er]=useState();
 const[basic_salary,setbasic_salary]=useState(0); const updatebasic_salary = (event) => { setbasic_salary(event.target.value);}; const[basic_salary_er,setbasic_salary_er]=useState();
 const[house_rental_allowance,sethouse_rental_allowance]=useState(0); const updatehouse_rental_allowance = (event) => { sethouse_rental_allowance(event.target.value);}; const[house_rental_allowance_er,sethouse_rental_allowance_er]=useState();
 const[travel_allowance,settravel_allowance]=useState(0); const updatetravel_allowance = (event) => { settravel_allowance(event.target.value);}; const[travel_allowance_er,settravel_allowance_er]=useState();
 const[dearance_allowance,setdearance_allowance]=useState(0); const updatedearance_allowance = (event) => { setdearance_allowance(event.target.value);}; const[dearance_allowance_er,setdearance_allowance_er]=useState();
 const[special_allowance,setspecial_allowance]=useState(0); const updatespecial_allowance = (event) => { setspecial_allowance(event.target.value);}; const[special_allowance_er,setspecial_allowance_er]=useState();
 const[incentives,setincentives]=useState(0); const updateincentives = (event) => { setincentives(event.target.value);}; const[incentives_er,setincentives_er]=useState();
 const[deductions,setdeductions]=useState(0); const updatedeductions = (event) => { setdeductions(event.target.value);}; const[deductions_er,setdeductions_er]=useState();
 const[pf_contribution,setpf_contribution]=useState(0); const updatepf_contribution = (event) => { setpf_contribution(event.target.value);}; const[pf_contribution_er,setpf_contribution_er]=useState();
 const[fixed_ctc,setfixed_ctc]=useState(0); const updatefixed_ctc = (event) => { setfixed_ctc(event.target.value);}; const[fixed_ctc_er,setfixed_ctc_er]=useState();
 const[hr_manager,sethr_manager]=useState(); const updatehr_manager = (event) => { sethr_manager(event.target.value);}; const[hr_manager_er,sethr_manager_er]=useState();
 const[incentive_remarks,setincentive_remarks]=useState(); const updateincentive_remarks = (event) => { setincentive_remarks(event.target.value);}; const[incentive_remarks_er,setincentive_remarks_er]=useState();
 const[address,setaddress]=useState(); const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
 const[city,setcity]=useState(); const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
 const[hstate,sethstate]=useState(); const updatehstate = (event) => { sethstate(event.target.value);}; const[hstate_er,sethstate_er]=useState();
 const[pincode,setpincode]=useState(); const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
 const[hemail,sethemail]=useState(); const updatehemail = (event) => { sethemail(event.target.value);}; const[hemail_er,sethemail_er]=useState();
 const[hcontact,sethcontact]=useState(); const updatehcontact = (event) => { sethcontact(event.target.value);}; const[hcontact_er,sethcontact_er]=useState();
 const[error_msg,seterror_msg]=useState();


  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/admin-dashboard-employee-offer-letter', { state: { date, offer_id, employee_code, employee_name, working_city, working_state, date_of_joining, join_before, department, designation, reporting_manager, gross_salary, gross_salary_inwords, basic_salary, house_rental_allowance, travel_allowance, dearance_allowance, special_allowance, incentives, deductions, pf_contribution, fixed_ctc, hr_manager, incentive_remarks, address, city, hstate, pincode, hemail, hcontact } });
  };





  
/***************** CREATE NEW OFFER LETTER FUNCTION START  ******************/
function create_offer_letter(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setoffer_id_er(" ");setdate_er(" ");setemployee_code_er(" "); setemployee_name_er(" ");setworking_city_er(" ");setworking_state_er(" ");setdate_of_joining_er(" ");
    setjoin_before_er(" ");setdepartment_er(" ");setdesignation_er(" ");setreporting_manager_er(" ");setgross_salary_er(" "); setgross_salary_inwords_er(" "); setfixed_ctc_er(" ");sethr_manager_er(" ");
    seterror_msg(" ");
   


  //Sending Data through axios
  axios.post("crm-create-new-offer-letter", { 
  Log_id:logid,
  Token:token,
  "Offer_id":document.getElementById("offer_id").value,
  "Date":document.getElementById("date").value,
  "Employee_code":document.getElementById("employee_code").value,
  "Employee_name":document.getElementById("employee_name").value,
  "Working_city":document.getElementById("working_city").value,
  "Working_state":document.getElementById("working_state").value,
  "Date_of_joining":document.getElementById("date_of_joining").value,
  "Reporting_date":document.getElementById("join_before").value,
  "Department":document.getElementById("department").value,
  "Designation":document.getElementById("designation").value,
  "Reporting_manager":document.getElementById("reporting_manager").value,
  "Gross_salary":document.getElementById("gross_salary").value,
  "Gross_salary_in_words":document.getElementById("gross_salary_inwords").value,
  "Fixed_ctc":document.getElementById("fixed_ctc").value,
  "HR_manager":document.getElementById("hr_manager").value,


  }
    )
  
  .then((response) => {
  
  //Validation Errors
  if(response.data.validatorerror){
  setoffer_id_er(response.data.validatorerror.Offer_id);
  setdate_er(response.data.validatorerror.Date);
  setemployee_code_er(response.data.validatorerror.Employee_code);
  setemployee_name_er(response.data.validatorerror.Employee_name);
  setworking_city_er(response.data.validatorerror.Working_city);
  setworking_state_er(response.data.validatorerror.Working_state);
  setdate_of_joining_er(response.data.validatorerror.Date_of_joining);
  setjoin_before_er(response.data.validatorerror.Reporting_date);
  setdepartment_er(response.data.validatorerror.Department);
  setdesignation_er(response.data.validatorerror.Designation);
  setreporting_manager_er(response.data.validatorerror.Reporting_manager);
  setgross_salary_er(response.data.validatorerror.Gross_salary);
  setgross_salary_inwords_er(response.data.validatorerror.Gross_salary_in_words);
  setfixed_ctc_er(response.data.validatorerror.Fixed_ctc);
  sethr_manager_er(response.data.validatorerror.HR_manager);
  seterror_msg("Cannot Save Offer Letter !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    setoffer_id_er(response.data.offer_id_er);
    setdate_er(response.data.date_er);
    setemployee_code_er(response.data.employee_code_er);
    setemployee_name_er(response.data.employee_name_er);
    setworking_city_er(response.data.working_city_er);
    setworking_state_er(response.data.working_state_er);
    setdate_of_joining_er(response.data.date_of_joining_er);
    setjoin_before_er(response.data.reporting_date_er);
    setdepartment_er(response.data.department_er);
    setdesignation_er(response.data.designation_er);
    setreporting_manager_er(response.data.reporting_manager_er);
    setgross_salary_er(response.data.gross_salary_er);
    setgross_salary_inwords_er(response.data.gross_salary_in_words_er);
    setfixed_ctc_er(response.data.fixed_ctc_er);
    sethr_manager_er(response.data.hr_manager_er);
  seterror_msg("Cannot Save Offer Letter !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE NEW OFFER LETTER FUNCTION END  ******************/

  


return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

         
            {/*  SUCCESS CARD START */}
            <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Offer Letter Saved Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={()=>{ document.getElementById("success-card").style.display="none"; }}>Continue</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/adminpanel-dashboard-employees")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CREATE NEW OFFER LETTER</p>
                  </a>     
                  <a className="ps-3 me-4" href="/adminpanel-dashboard-offer-letters-history">
                    <p className="m-0 text-primary">View History</p>
                  </a>     
                </div>
              
             {/*}  <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
               <i className="mdi mdi-plus-circle"></i>View History</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Offer Letter</h4>
                    <div class="form-sample">
                      <p class="card-description">create new offer letter</p>
                      <code>{error_msg}</code> 
                      <br/>
                      <br/>

                      <form onSubmit={handleSubmit}>
     
   

                     <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="24-08-2024" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Offer Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="JHS7S878" id="offer_id" value={offer_id} onChange={updateoffer_id} />
                              <code>{offer_id_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Header Address</label>
                            <div class="col-sm-9">
                              <input type="address" class="form-control" placeholder="56 A Here" id="address" value={address} onChange={updateaddress} />
                              <code>{address_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Header City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna" id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Header State</label>
                            <div class="col-sm-9">
                              <input type="hstate" class="form-control" placeholder="Madhya Pradesh" id="hstate" value={hstate} onChange={updatehstate} />
                              <code>{hstate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Header Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="456668" id="pincode" value={pincode} onChange={updatepincode} />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Header Email</label>
                            <div class="col-sm-9">
                              <input type="hemail" class="form-control" placeholder="myemail@gmail.com" id="hemail" value={hemail} onChange={updatehemail} />
                              <code>{hemail_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Header Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9898989898" id="hcontact" value={hcontact} onChange={updatehcontact} />
                              <code>{hcontact_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="EMP99898" id="employee_code" value={employee_code} onChange={updateemployee_code} />
                              <code>{employee_code_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MR. KARAN KUMAR" id="employee_name" value={employee_name} onChange={updateemployee_name} />
                              <code>{employee_name_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Working City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna" id="working_city" value={working_city} onChange={updateworking_city} />
                              <code>{working_city_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Working State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh" id="working_state" value={working_state} onChange={updateworking_state} />
                              <code>{working_state_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Joining</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7th September 2024, Friday" id="date_of_joining" value={date_of_joining} onChange={updatedate_of_joining} />
                              <code>{date_of_joining_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Reporting Date</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5th September" id="join_before" value={join_before} onChange={updatejoin_before} />
                              <code>{join_before_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Department</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Business Development, Sales" id="department" value={department} onChange={updatedepartment} />
                              <code>{department_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Designation</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Head of Sales" id="designation" value={designation} onChange={updatedesignation} />
                              <code>{designation_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Reporting Manager</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MR. KAMAL RAJAK" id="reporting_manager" value={reporting_manager} onChange={updatereporting_manager} />
                              <code>{reporting_manager_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gross Salary</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="280000" id="gross_salary" value={gross_salary} onChange={updategross_salary} />
                              <code>{gross_salary_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gross Salary in words</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="RUPEES TWENTY EIGHT THOUSAND ONLY" id="gross_salary_inwords" value={gross_salary_inwords} onChange={updategross_salary_inwords} />
                              <code>{gross_salary_inwords_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Basic Pay</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="280000" id="basic_salary" value={basic_salary} onChange={updatebasic_salary} />
                              <code>{basic_salary_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">House Rental Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="8000" id="house_rental_allowance" value={house_rental_allowance} onChange={updatehouse_rental_allowance} />
                              <code>{house_rental_allowance_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Travel Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="80000" id="travel_allowance" value={travel_allowance} onChange={updatetravel_allowance} />
                              <code>{travel_allowance_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Dearance Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7000" id="dearance_allowance" value={dearance_allowance} onChange={updatedearance_allowance} />
                              <code>{dearance_allowance_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Special Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7000" id="special_allowance" value={special_allowance} onChange={updatespecial_allowance} />
                              <code>{special_allowance_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentives</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7000" id="incentives" value={incentives} onChange={updateincentives} />
                              <code>{incentives_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Incentive Remarks</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000" id="incentive_remarks" value={incentive_remarks} onChange={updateincentive_remarks} />
                              <code>{incentive_remarks_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deductions</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000" id="deductions" value={deductions} onChange={updatedeductions} />
                              <code>{deductions_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">PF Contribution</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000" id="pf_contribution" value={pf_contribution} onChange={updatepf_contribution} />
                              <code>{pf_contribution_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Fixed CTC</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="70000" id="fixed_ctc" value={fixed_ctc} onChange={updatefixed_ctc} />
                              <code>{fixed_ctc_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">HR Manager</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MR. SOHAN VERMA" id="hr_manager" value={hr_manager} onChange={updatehr_manager} />
                              <code>{hr_manager_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <button type="submit" class="btn btn-lg btn-success" style={{fontWeight:"bold"}}>Create Offer Letter</button>
                        </form>
                        <button type="submit" class="btn btn-lg btn-primary" style={{fontWeight:"bold", marginTop:"1rem"}} onClick={create_offer_letter}>Save Offer Letter</button>
                     


               
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_create_offer_letter;