import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';


function Employee_loan_application(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");
 const custId=sessionStorage.getItem("CustomerId00");

 const[customer_id,setcustomer_id]=useState();const updatecustomer_id = (event) => { setcustomer_id(event.target.value);}; const[customer_id_er,setcustomer_id_er]=useState();
 const[scheme_code,setscheme_code]=useState();const updatescheme_code = (event) => { setscheme_code(event.target.value);}; const[scheme_code_er,setscheme_code_er]=useState();
 const[cibil_score,setcibil_score]=useState();const updatecibil_score = (event) => { setcibil_score(event.target.value);}; const[cibil_score_er,setcibil_score_er]=useState();
 const[date_of_cibil,setdate_of_cibil]=useState();const updatedate_of_cibil = (event) => { setdate_of_cibil(event.target.value);}; const[date_of_cibil_er,setdate_of_cibil_er]=useState();
 const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
 const[loan_purpose,setloan_purpose]=useState();const updateloan_purpose = (event) => { setloan_purpose(event.target.value);}; const[loan_purpose_er,setloan_purpose_er]=useState();
 const[full_name_for_bank,setfull_name_for_bank]=useState();const updatefull_name_for_bank = (event) => { setfull_name_for_bank(event.target.value);}; const[full_name_for_bank_er,setfull_name_for_bank_er]=useState();
 const[ifsc_code,setifsc_code]=useState();const updateifsc_code = (event) => { setifsc_code(event.target.value);}; const[ifsc_code_er,setifsc_code_er]=useState();
 const[account_number,setaccount_number]=useState();const updateaccount_number = (event) => { setaccount_number(event.target.value);}; const[account_number_er,setaccount_number_er]=useState();
 const[confirm_account_number,setconfirm_account_number]=useState();const updateconfirm_account_number = (event) => { setconfirm_account_number(event.target.value);}; const[confirm_account_number_er,setconfirm_account_number_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
 const[loan_remarks,setloan_remarks]=useState();const updateloan_remarks = (event) => { setloan_remarks(event.target.value);}; const[loan_remarks_er,setloan_remarks_er]=useState();
 const[monthly_income,setmonthly_income]=useState();const updatemonthly_income = (event) => { setmonthly_income(event.target.value);}; const[monthly_income_er,setmonthly_income_er]=useState();
 const[document_file_name,setdocument_file_name]=useState();const updatedocument_file_name = (event) => { setdocument_file_name(event.target.value);}; const[document_file_name_er,setdocument_file_name_er]=useState();
 const[document_file_link,setdocument_file_link]=useState();const updatedocument_file_link = (event) => { setdocument_file_link(event.target.value);}; const[document_file_link_er,setdocument_file_link_er]=useState();

 const[coapplicant_name,setcoapplicant_name]=useState();const updatecoapplicant_name = (event) => { setcoapplicant_name(event.target.value);}; const[coapplicant_name_er,setcoapplicant_name_er]=useState();
 const[coapplicant_fathers_name,setcoapplicant_fathers_name]=useState();const updatecoapplicant_fathers_name = (event) => { setcoapplicant_fathers_name(event.target.value);}; const[coapplicant_fathers_name_er,setcoapplicant_fathers_name_er]=useState();
 const[coapplicant_cibil_score,setcoapplicant_cibil_score]=useState();const updatecoapplicant_cibil_score = (event) => { setcoapplicant_cibil_score(event.target.value);}; const[coapplicant_cibil_score_er,setcoapplicant_cibil_score_er]=useState();
 const[coapplicant_cibil_date,setcoapplicant_cibil_date]=useState();const updatecoapplicant_cibil_date = (event) => { setcoapplicant_cibil_date(event.target.value);}; const[coapplicant_cibil_date_er,setcoapplicant_cibil_date_er]=useState();
 const[coapplicant_relation,setcoapplicant_relation]=useState();const updatecoapplicant_relation = (event) => { setcoapplicant_relation(event.target.value);}; const[coapplicant_relation_er,setcoapplicant_relation_er]=useState();
 const[coapplicant_contact,setcoapplicant_contact]=useState();const updatecoapplicant_contact = (event) => { setcoapplicant_contact(event.target.value);}; const[coapplicant_contact_er,setcoapplicant_contact_er]=useState();
 const[coapplicant_address,setcoapplicant_address]=useState();const updatecoapplicant_address = (event) => { setcoapplicant_address(event.target.value);}; const[coapplicant_address_er,setcoapplicant_address_er]=useState();


 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

 //image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
 const[employee_image_link_er,setemployee_image_link_er]=useState();
 
 //image upload
 //File Upload
 const [selectedFile1, setSelectedFile1] = React.useState(null);
 const handleFileChange1 = (event) => {  const file1 = event.target.files[0];  setSelectedFile1(file1); };
 const[employee_docs_link_er,setemployee_docs_link_er]=useState();
 

 

/***************** CREATE LOAN APPLICATION FUNCTION START  ******************/
function create_loan_application(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(branch_id>0)&&((custId!=null)||(custId!=""))){

    //Setting Error Variables
    setscheme_code_er(" ");setcibil_score_er(" ");setdate_of_cibil_er(" ");setbank_name_er(" ");setloan_purpose_er(" ");setfull_name_for_bank_er(" ");
    setifsc_code_er(" ");setaccount_number_er(" ");setconfirm_account_number_er(" ");settype_er(" ");setloan_remarks_er(" ");setmonthly_income_er(" ");
    setdocument_file_name_er(" ");setdocument_file_link_er(" ");seterror_msg(" ");settype_er(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $scheme_code = document.getElementById("scheme_code").value; if($scheme_code==""){ setscheme_code_er("Scheme Code is required !");noerror = noerror+1; }//scheme_code
const $cibil_score = document.getElementById("cibil_score").value; if($cibil_score==""){ setcibil_score_er("Cibil Score is required !");noerror = noerror+1; }//cibil_score
const $date_of_cibil = document.getElementById("date_of_cibil").value; if($date_of_cibil==""){ setdate_of_cibil_er("Date of Cibil is required !");noerror = noerror+1; }//date_of_cibil
const $bank_name = document.getElementById("bank_name").value; if($bank_name==""){ setbank_name_er("Bank Name is required !");noerror = noerror+1; }//bank_name
const $loan_purpose = document.getElementById("loan_purpose").value; if($loan_purpose==""){ setloan_purpose_er("Loan purpose is required !");noerror = noerror+1; }//loan_purpose
const $full_name_for_bank = document.getElementById("full_name_for_bank").value; if($full_name_for_bank==""){ setfull_name_for_bank_er("Full Name for Bank is required !");noerror = noerror+1; }//full_name_for_bank
const $ifsc_code = document.getElementById("ifsc_code").value; if($ifsc_code==""){ setifsc_code_er("Ifsc Code is required !");noerror = noerror+1; }//ifsc_code
const $account_number = document.getElementById("account_number").value; if($account_number==""){ setaccount_number_er("Account Number is required !");noerror = noerror+1; }//account_number
const $confirm_account_number = document.getElementById("confirm_account_number").value; if($confirm_account_number==""){ setconfirm_account_number_er("Confirm Account Number is required !");noerror = noerror+1; }//confirm_account_number
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//type
const $loan_remarks = document.getElementById("loan_remarks").value; if($loan_remarks==""){ setloan_remarks_er("Loan Remarks is required !");noerror = noerror+1; }//loan_remarks
const $monthly_income = document.getElementById("monthly_income").value; if($monthly_income==""){ setmonthly_income_er("Monthly Income is required !");noerror = noerror+1; }//monthly_income
const $document_file_name = document.getElementById("document_file_name").value; if($document_file_name==""){ setdocument_file_name_er("Document File Name is required !");noerror = noerror+1; }//document_file_name
const $document_file_link = document.getElementById("document_file_link").value; if($document_file_link==""){ setdocument_file_link_er("Document File Link is required !");noerror = noerror+1; }//document_file_link


//No primary errors
if(noerror==0){

    //disabling button
    document.getElementById('emp-submit-btn').disabled = true; 

  //Sending Data through axios
  axios.post("crm-create-loan-application", { 
  Log_id:logid,
  Token:token,
  Branch_id:branch_id,
  Customer_id:custId,
  Scheme_code:$scheme_code,
  Bank_name:$bank_name,
  Loan_purpose:$loan_purpose,
  Full_name_for_bank:$full_name_for_bank,
  Ifsc_code:$ifsc_code,
  Account_number:$account_number,
  Confirm_account_number:$confirm_account_number,
  Cibil_score:$cibil_score,
  Date_of_cibil:moment($date_of_cibil).format('YYYY-MM-DD'),
  Type:$type,
  Loan_remarks:$loan_remarks,
  Monthly_income:$monthly_income,
  Document_file_name:$document_file_name,
  Document_file_link:$document_file_link,
  Loan_remarks:$loan_remarks,
  Cibil_image_link:selectedFile,
  Loan_consent_link:selectedFile1,
  Coapplicant_name:document.getElementById("coapplicant_name").value,
  Coapplicant_fathers_name:document.getElementById("coapplicant_fathers_name").value,
  Coapplicant_cibil_score:document.getElementById("coapplicant_cibil_score").value,
  Coapplicant_cibil_date:moment(document.getElementById("coapplicant_cibil_date").value).format('YYYY-MM-DD'),
  Coapplicant_contact:document.getElementById("coapplicant_contact").value,
  Coapplicant_relation:document.getElementById("coapplicant_relation").value,
  Coapplicant_address:document.getElementById("coapplicant_address").value,
  Type:"regular"

  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

      //enabling button
      document.getElementById('emp-submit-btn').disabled = false; 

    
  //Validation Errors
  if(response.data.validatorerror){
  setcustomer_id_er(response.data.validatorerror.Customer_id);
  setscheme_code_er(response.data.validatorerror.Scheme_code);
  setcibil_score_er(response.data.validatorerror.Cibil_score);
  setdate_of_cibil_er(response.data.validatorerror.Date_of_cibil);
  setbank_name_er(response.data.validatorerror.Bank_name);
  setloan_purpose_er(response.data.validatorerror.Loan_purpose);
  setfull_name_for_bank_er(response.data.validatorerror.Full_name_for_bank);
  setifsc_code_er(response.data.validatorerror.Ifsc_code);
  setaccount_number_er(response.data.validatorerror.Account_number);
  setconfirm_account_number_er(response.data.validatorerror.Confirm_account_number);
  settype_er(response.data.validatorerror.Type);
  setloan_remarks_er(response.data.validatorerror.Loan_remarks);
  setmonthly_income_er(response.data.validatorerror.Monthly_income);
  setdocument_file_name_er(response.data.validatorerror.Document_file_name);
  setdocument_file_link_er(response.data.validatorerror.Document_file_link);
  setemployee_image_link_er(response.data.validatorerror.Cibil_image_link);
  setemployee_docs_link_er(response.data.validatorerror.Loan_consent_link);
  setloan_remarks_er(response.data.validatorerror.Loan_remarks);
  settype_er(response.data.validatorerror.Type);
  setcoapplicant_name_er(response.data.validatorerror.Coapplicant_name);
  setcoapplicant_fathers_name_er(response.data.validatorerror.Coapplicant_fathers_name);
  setcoapplicant_contact_er(response.data.validatorerror.Coapplicant_contact);
  setcoapplicant_relation_er(response.data.validatorerror.Coapplicant_relation);
  setcoapplicant_cibil_score_er(response.data.validatorerror.Coapplicant_cibil_score);
  setcoapplicant_cibil_date_er(response.data.validatorerror.Coapplicant_cibil_date);
  setcoapplicant_address_er(response.data.validatorerror.Coapplicant_address);
  seterror_msg("Loan Application Not Created !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    setcustomer_id_er(response.data.customer_id_er);
    setscheme_code_er(response.data.scheme_code_er);
    setcibil_score_er(response.data.cibil_score_er);
    setdate_of_cibil_er(response.data.date_of_cibil_er);
    setbank_name_er(response.data.bank_name_er);
    setloan_purpose_er(response.data.loan_purpose_er);
    setfull_name_for_bank_er(response.data.full_name_for_bank_er);
    setifsc_code_er(response.data.ifsc_code_er);
    setaccount_number_er(response.data.account_number_er);
    setconfirm_account_number_er(response.data.confirm_account_number_er);
    settype_er(response.data.type_er);
    setloan_remarks_er(response.data.loan_remarks_er);
    setmonthly_income_er(response.data.monthly_income_er);
    setdocument_file_name_er(response.data.document_file_name_er);
    setdocument_file_link_er(response.data.document_file_link_er);
    setemployee_image_link_er(response.data.employee_image_link_er);
    setemployee_docs_link_er(response.data.employee_docs_link_er);
    setloan_remarks_er(response.data.loan_remarks_er);
    settype_er(response.data.type_er);
    setcoapplicant_name_er(response.data.coapplicant_name_er);
    setcoapplicant_fathers_name_er(response.data.coapplicant_fathers_name_er);
    setcoapplicant_contact_er(response.data.coapplicant_contact_er);
    setcoapplicant_relation_er(response.data.coapplicant_relation_er);
    setcoapplicant_cibil_score_er(response.data.coapplicant_cibil_score_er);
    setcoapplicant_cibil_date_er(response.data.coapplicant_cibil_date_er);
    setcoapplicant_address_er(response.data.coapplicant_address_er);
    seterror_msg("Loan Application Not Created !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Loan Application Not Created !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }

}
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE LOAN APPLICATION FUNCTION END  ******************/


 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Loan Request Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/employee-dashboard-customer-loans"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-loan-requests")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">NEW LOAN REQUEST</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Loan Request</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="C656666" value={custId} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LS1234567" id="scheme_code" value={scheme_code} onChange={updatescheme_code} />
                              <code>{scheme_code_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Cibil Score</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="700" id="cibil_score" value={cibil_score} onChange={updatecibil_score} />
                              <code>{cibil_score_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Cibil Statement</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date_of_cibil" value={date_of_cibil} onChange={updatedate_of_cibil} />
                              <code>{date_of_cibil_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="form-group">
                        <label for="exampleTextarea1">Purpose of Loan</label>
                        <textarea  class="form-control" rows="2" placeholder="loan purpose here" id="loan_purpose" value={loan_purpose} onChange={updateloan_purpose} />
                        <code>{loan_purpose_er}</code>
                        </div>


                        <hr/>
                        <h4 class="card-title">Co Applicant Details</h4>
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Full Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Santosh Kumar" id="coapplicant_name" onChange={updatecoapplicant_name} value={coapplicant_name} />
                              <code>{coapplicant_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Father's Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Hari Kumar" id="coapplicant_fathers_name" value={coapplicant_fathers_name} onChange={updatecoapplicant_fathers_name} />
                              <code>{coapplicant_fathers_name_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Cibil Score</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="700" id="coapplicant_cibil_score" value={coapplicant_cibil_score} onChange={updatecoapplicant_cibil_score} />
                              <code>{coapplicant_cibil_score_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Cibil Statement</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="coapplicant_cibil_date" value={coapplicant_cibil_date} onChange={updatecoapplicant_cibil_date} />
                              <code>{coapplicant_cibil_date_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Relation to Applicant</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="700" id="coapplicant_relation" value={coapplicant_relation} onChange={updatecoapplicant_relation} />
                              <code>{coapplicant_relation_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91980000000" id="coapplicant_contact" value={coapplicant_contact} onChange={updatecoapplicant_contact} />
                              <code>{coapplicant_contact_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2" placeholder="address here" id="coapplicant_address" value={coapplicant_address} onChange={updatecoapplicant_address} />
                        <code>{coapplicant_address_er}</code>
                        </div>
                 

                       <hr/>
                        <h4>Banking Details for Amount Transfer</h4>
                        <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="SBI" id="bank_name" value={bank_name} onChange={updatebank_name} />
                              <code>{bank_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Full Name as per Bank</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="SUMIT RANJAN" id="full_name_for_bank" value={full_name_for_bank} onChange={updatefull_name_for_bank} />
                              <code>{full_name_for_bank_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IFSC Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HS66S56544" id="ifsc_code" value={ifsc_code} onChange={updateifsc_code} />
                              <code>{ifsc_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype}>
                              <option value=" ">- select account type -</option>
                              <option value="savings account">Savings Account</option>
                              <option value="current account">Current Account</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="15554454545454545" id="account_number" value={account_number} onChange={updateaccount_number} />
                              <code>{account_number_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Account Number</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="**********************" id="confirm_account_number" value={confirm_account_number} onChange={updateconfirm_account_number} />
                              <code>{confirm_account_number_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Monthly Income</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="15000" id="monthly_income" value={monthly_income} onChange={updatemonthly_income} />
                              <code>{monthly_income_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                     
                       <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="remarks here" id="loan_remarks" value={loan_remarks} onChange={updateloan_remarks} />
                        <code>{loan_remarks_er}</code>
                       </div>
                        <div>
                        <hr/>
                        <h4>Attach Documents</h4>
                        <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents File Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Customer 1234" id="document_file_name" value={document_file_name} onChange={updatedocument_file_name}  />
                              <code>{document_file_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents Link</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="https://mydrivelink.com/mydrive" id="document_file_link" value={document_file_link} onChange={updatedocument_file_link} />
                              <code>{document_file_link_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Cibil Proof (Image)</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{employee_image_link_er}</code>
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Self Certification and Consent</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link1" onChange={handleFileChange1} multiple />
                              {selectedFile1 && <p>Selected file: {selectedFile1.name}</p>}
                              <code>{employee_docs_link_er}</code>
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.pdf), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>


                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} id="emp-submit-btn" onClick={create_loan_application}>Submit</button>
                     

                        <hr/>
                            <h4>Check Cibil Score from following links</h4>
                            <a href="https://www.cibil.com/freecibilscore">https://www.cibil.com/freecibilscore</a>
                            <br/>
                            <a href="https://www.bajajfinserv.in/check-free-cibil-score">https://www.bajajfinserv.in/check-free-cibil-score</a>
                            <br/>
                            <a href="https://www.paisabazaar.com/cibil-credit-report/">https://www.paisabazaar.com/cibil-credit-report/</a>
                            <br/>
                            <a href="https://creditscore.iifl.com/Cibil/Report">https://creditscore.iifl.com/Cibil/Report</a>
                            <br/>
                            <a href="https://www.bankbazaar.com/cibil/cibil-credit-score.html?ck=Y%2BziX71XnZjIM9ZwEflsyKLke1Pbw7p7MfBTjH9qcYlAevB1eS8eCGuBkX8I0T6I">https://www.bankbazaar.com/cibil/cibil-credit-score.html?ck=Y%2BziX71XnZjIM9ZwEflsyKLke1Pbw7p7MfBTjH9qcYlAevB1eS8eCGuBkX8I0T6I</a>
                            <br/>
                            <a href="https://www.wishfin.com/cibil-score">https://www.wishfin.com/cibil-score</a>
                        </div>
                     

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                       
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_loan_application;