import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';



function Admin_loan_scheme_details(){

  
    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");


 
 //SETTING DATA VARIABLES
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[loan_amount,setloan_amount]=useState();
 const[interest_rate,setinterest_rate]=useState();
 const[duration,setduration]=useState();
 const[minimum_monthly_income,setminimum_monthly_income]=useState();
 const[scheme_status,setscheme_status]=useState();
 const[type,settype]=useState();
 const[pf_per,setpf_per]=useState();
 const[pf_amount,setpf_amount]=useState();
 const[death_insurance,setdeath_insurance]=useState();
 const[health_insurance,sethealth_insurance]=useState();
 const[gst_per,setgst_per]=useState();
 const[gst_amount,setgst_amount]=useState();
 const[total_additions,settotal_additions]=useState();
 const[loan_interest,setloan_interest]=useState();
 const[repayable_amount,setrepayable_amount]=useState();
 const[emi,setemi]=useState();
 const[details,setdetails]=useState();
 const[scheme_document_link,setscheme_document_link]=useState();
 const[insurance_gst,setinsurance_gst]=useState();
 const[interest_gst,setinterest_gst]=useState();
 const[emi_principle,setemi_principle]=useState();
 const[emi_interest,setemi_interest]=useState();
 const[emi_gst,setemi_gst]=useState();


  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  
  // gdrive data
  const[gdrive_data,setgdrive_data]=useState([],);

 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* GET SCHEME DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-get-loan-scheme-details-by-id", {
   Log_id:logid,
   Token:token,
   Edit_id:editId


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Scheme Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setscheme_name(response.data.scheme_name);
   setscheme_code(response.data.scheme_code);
   settype(response.data.type);
   setloan_amount(response.data.loan_amount);
   setinterest_rate(response.data.interest_rate);
   setpf_per(response.data.pf_per);
   setpf_amount(response.data.pf_amount);
   setdeath_insurance(response.data.death_insurance);
   sethealth_insurance(response.data.health_insurance);
   setgst_per(response.data.gst_per);
   setgst_amount(response.data.gst_amount);
   settotal_additions(response.data.total_additions);
   setloan_interest(response.data.loan_interest);
   setduration(response.data.duration);
   setminimum_monthly_income(response.data.minimum_monthly_income);
   setrepayable_amount(response.data.repayable_amount);
   setemi(response.data.emi);
   setdetails(response.data.details);
   setscheme_document_link(response.data.scheme_document_link);
   setscheme_status(response.data.scheme_status);
   setinsurance_gst(response.data.insurance_gst);
   setinterest_gst(response.data.interest_gst);
   setemi_principle(response.data.emi_principle);
   setemi_interest(response.data.emi_interest);
   setemi_gst(response.data.emi_gst);
   
   //Setting gdrive data
   setgdrive_data(response.data.gdrive_data);

  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET SCHEME DATA FUNCTION END  ********************//

 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/adminpanel-dashboard-loan-schemes")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">LOAN SCHEME DETAILS</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h3 class="card-title">LOAN SCHEME DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row" style={{textAlign:"left"}}>
                  
                    <div class="col-md-12">
                    <div class="row" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h3><b>Scheme Name: </b></h3></div>
                    <div class="row col-sm-6"><h3> {scheme_name}</h3></div>
                    </div>
                      
                      <hr/>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Scheme Code: </b></h4></div>
                    <div class="row col-sm-6"><h4>{scheme_code}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Loan Amount: </b></h4></div>
                    <div class="row col-sm-6"><h4>{loan_amount}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Interest Rate: </b></h4></div>
                    <div class="row col-sm-6"><h4>{interest_rate}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>PF %: </b></h4></div>
                    <div class="row col-sm-6"><h4>{pf_per}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>PF Amount: </b></h4></div>
                    <div class="row col-sm-6"><h4>{pf_amount}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Status</b></h4></div>
                    <div class="row col-sm-6"><div><label className="badge badge-success" hidden={scheme_status=="inactive"} >active</label><label className="badge badge-danger" hidden={scheme_status=="active"} >active</label></div></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Death Insurance: </b></h4></div>
                    <div class="row col-sm-6"><h4>{death_insurance}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Health Insurance: </b></h4></div>
                    <div class="row col-sm-6"><h4>{health_insurance}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Insurance GST @ 18%: </b></h4></div>
                    <div class="row col-sm-6"><h4>{insurance_gst}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Duration (in weeks): </b></h4></div>
                    <div class="row col-sm-6"><h4>{duration}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Interest for Tenure: </b></h4></div>
                    <div class="row col-sm-6"><h4>{loan_interest}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>GST on Interest @18%: </b></h4></div>
                    <div class="row col-sm-6"><h4>{interest_gst}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>GST % </b></h4></div>
                    <div class="row col-sm-6"><h4>{gst_per}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Total GST Amount: </b></h4></div>
                    <div class="row col-sm-6"><h4>{gst_amount}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Total Additions to Loan Amount:</b></h4></div>
                    <div class="row col-sm-6"><h4>{total_additions}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Minimum Monthly Income: </b></h4></div>
                    <div class="row col-sm-6"><h4>{minimum_monthly_income}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Total Repayable Amount: </b></h4></div>
                    <div class="row col-sm-6"><h4>{repayable_amount}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>EMI: </b></h4></div>
                    <div class="row col-sm-6"><h4>{emi}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>EMI Principle: </b></h4></div>
                    <div class="row col-sm-6"><h4>{emi_principle}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>EMI Interest: </b></h4></div>
                    <div class="row col-sm-6"><h4>{emi_interest}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>EMI GST: </b></h4></div>
                    <div class="row col-sm-6"><h4>{emi_gst}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Type: </b></h4></div>
                    <div class="row col-sm-6"><h4>{type}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Document File: </b></h4></div>
                    <div class="row col-sm-6"><h4>(<a href={scheme_document_link}>{scheme_document_link}</a>)</h4></div>
                    </div>

                    </div>
                    </div>
                  <hr/>
                  <h3><b>Scheme Details</b></h3>
                  <p>{details}</p>

                      {/* G DRIVE DATA SECTION START */}
                     <hr/>
                    <h3 style={{textAlign:"center"}}>Gdrive Documents</h3>
                    <div className="table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                     {/*}     <th style={{fontWeight:"bold"}} >Doc Id</th> */}
                            <th  style={{fontWeight:"bold"}} >Doc Name</th>
                            <th  style={{fontWeight:"bold"}} >Doc Type</th>
                            <th  style={{fontWeight:"bold"}} >Doc Link</th>
                            <th  style={{fontWeight:"bold"}} >Updated At</th>
                         {/*}   <th  style={{fontWeight:"bold"}} >Updated By</th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {
                       gdrive_data.map((curElm1,ind)=>{

                         return(
                         <>
                        <tr>
                        {/*}  <td>{curElm1.Doc_id}</td> */}
                          <td>{curElm1.Doc_name}</td>
                          <td>{curElm1.Doc_type}</td>
                          <td><a href={curElm1.Doc_link}>{curElm1.Doc_link}</a></td>
                          <td>{curElm1.Date_updated} ({curElm1.Time_updated})</td>   
                        {/*}  <td>{curElm1.Updated_by}</td>        */}          
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      
                       
                          
                         
                        </tbody>
                      </table>
                    </div>
                    {/* G DRIVE DATA SECTION END */}  
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_loan_scheme_details;