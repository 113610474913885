import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import edit_loanrequests from './edit_newaccounts';



function Admin_edit_expense(){

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");

 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


useEffect(()=>{
   
    getData();
    getData1();
    
    },[])


    

//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("crm-admin-get-banks-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//

 const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
 const[voucher_no,setvoucher_no]=useState();const updatevoucher_no = (event) => { setvoucher_no(event.target.value);}; const[voucher_no_er,setvoucher_no_er]=useState();
 const[gstin,setgstin]=useState();const updategstin = (event) => { setgstin(event.target.value);}; const[gstin_er,setgstin_er]=useState();
 const[title,settitle]=useState();const updatetitle = (event) => { settitle(event.target.value);}; const[title_er,settitle_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
 const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
 const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value); calculate(); }; const[amount_er,setamount_er]=useState();
 const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
 const[bank_name,setbank_name]=useState();//const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
 const[total,settotal]=useState();const updatetotal = (event) => { settotal(event.target.value);}; const[total_er,settotal_er]=useState();
 const[discount_per,setdiscount_per]=useState();const updatediscount_per = (event) => { setdiscount_per(event.target.value); calculate();}; const[discount_per_er,setdiscount_per_er]=useState();
 const[discount_amt,setdiscount_amt]=useState();const updatediscount_amt = (event) => { setdiscount_amt(event.target.value); calculate();}; const[discount_amt_er,setdiscount_amt_er]=useState();
 const[sgst_per,setsgst_per]=useState();const updatesgst_per = (event) => { setsgst_per(event.target.value); calculate();}; const[sgst_per_er,setsgst_per_er]=useState();
 const[sgst_amt,setsgst_amt]=useState();const updatesgst_amt = (event) => { setsgst_amt(event.target.value); calculate();}; const[sgst_amt_er,setsgst_amt_er]=useState();
 const[cgst_per,setcgst_per]=useState();const updatecgst_per = (event) => { setcgst_per(event.target.value); calculate();}; const[cgst_per_er,setcgst_per_er]=useState();
 const[cgst_amt,setcgst_amt]=useState();const updatecgst_amt = (event) => { setcgst_amt(event.target.value); calculate();}; const[cgst_amt_er,setcgst_amt_er]=useState();
 const[igst_per,setigst_per]=useState();const updateigst_per = (event) => { setigst_per(event.target.value); calculate();}; const[igst_per_er,setigst_per_er]=useState();
 const[igst_amt,setigst_amt]=useState();const updateigst_amt = (event) => { setigst_amt(event.target.value); calculate();}; const[igst_amt_er,setigst_amt_er]=useState();


 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

 //image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
 const[attachment_link_er,setattachment_link_er]=useState();


 
 //******************* GET TRANSACTION DATA FUNCTION START  ********************//
function getData1(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-admin-get-expense-transaction-details-by-id", {
   Log_id:logid,
   Token:token,
   Edit_id:editId


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Transaction No. !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setdate(response.data.date);
   setvoucher_no(response.data.voucher_no);
   setgstin(response.data.gstin);
   settitle(response.data.title);
   settype(response.data.type);
   setparticular(response.data.particular);
   setamount(response.data.amount);
   setbank_id(response.data.bank_id);
   setbank_name(response.data.bank_name);
   settotal(response.data.total);
   setdiscount_per(response.data.discount_per);
   setdiscount_amt(response.data.discount_amt);
   setsgst_per(response.data.sgst_per);
   setsgst_amt(response.data.sgst_amt);
   setcgst_per(response.data.cgst_per);
   setcgst_amt(response.data.cgst_amt);
   setigst_per(response.data.igst_per);
   setigst_amt(response.data.igst_amt);
   
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET TRANSACTION DATA FUNCTION END  ********************//


 
   
/***************** EDIT TRANSACTION FUNCTION START  ******************/
function edit_transaction(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(editId>0)){


    //Setting Error Variables
    setdate_er(" ");setvoucher_no_er(" ");setgstin_er(" ");settitle_er(" ");settype_er(" ");setparticular_er(" ");setamount_er(" ");settotal_er(" ");setbank_id_er(" "); 
    setdiscount_per_er(" ");setdiscount_amt_er(" ");setsgst_per_er(" ");setsgst_amt_er(" ");setcgst_per_er(" ");setcgst_amt_er(" ");setigst_per_er(" ");setigst_amt_er(" ");
    seterror_msg(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $date = document.getElementById("date").value; if($date==""){ setdate_er("Date is required !");noerror = noerror+1; }//date
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//type
const $particular = document.getElementById("particular").value; if($particular==""){ setparticular_er("Particular is required !");noerror = noerror+1; }//particular
const $voucher_no = document.getElementById("voucher_no").value; if($voucher_no==""){ setvoucher_no_er("Voucher_no is required !");noerror = noerror+1; }//voucher_no
const $title = document.getElementById("title").value; if($title==""){ settitle_er("Title is required !");noerror = noerror+1; }//title
const $amount = document.getElementById("amount").value; if($amount==""){ setamount_er("Amount is required !");noerror = noerror+1; }//amount
const $total = document.getElementById("total").value;//total
const $bank_id = document.getElementById("bank_id").value;//bank_id
const $gstin = document.getElementById("gstin").value;//total
const $discount_per = document.getElementById("discount_per").value;//discount_per
const $discount_amt = document.getElementById("discount_amt").value;//discount_amt
const $sgst_per = document.getElementById("sgst_per").value;//sgst_per
const $sgst_amt = document.getElementById("sgst_amt").value;//sgst_amt
const $cgst_per = document.getElementById("cgst_per").value;//cgst_per
const $cgst_amt = document.getElementById("cgst_amt").value;//cgst_amt
const $igst_per = document.getElementById("igst_per").value;//igst_per
const $igst_amt = document.getElementById("igst_amt").value;//igst_amt

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-admin-edit-expense-transaction-details", { 
  Log_id:logid,
  Token:token,
  Date:$date,
  Type:$type,
  Particular:$particular,
  Voucher_no:$voucher_no,
  Title:$title,
  Amount:$amount,
  Total:$total,
  Bank_id:$bank_id,
  Gstin:$gstin,
  Discount_per:$discount_per,
  Discount_amt:$discount_amt,
  Sgst_per:$sgst_per,
  Sgst_amt:$sgst_amt,
  Cgst_per:$cgst_per,
  Cgst_amt:$cgst_amt,
  Igst_per:$igst_per,
  Igst_amt:$igst_amt,
  File_attachment:selectedFile,
  Edit_id:editId
 
  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

  //Validation Errors
  if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  settype_er(response.data.validatorerror.Type);
  setparticular_er(response.data.validatorerror.Particular);
  setvoucher_no_er(response.data.validatorerror.Voucher_no);
  setgstin_er(response.data.validatorerror.Gstin);
  settitle_er(response.data.validatorerror.Title);
  setamount_er(response.data.validatorerror.Amount);
  settotal_er(response.data.validatorerror.Total);
  setbank_id_er(response.data.validatorerror.Bank_id);
  setdiscount_per_er(response.data.validatorerror.Discount_per);
  setdiscount_amt_er(response.data.validatorerror.Discount_amt);
  setsgst_per_er(response.data.validatorerror.Sgst_per);
  setsgst_amt_er(response.data.validatorerror.Sgst_amt);
  setcgst_per_er(response.data.validatorerror.Cgst_per);
  setcgst_amt_er(response.data.validatorerror.Cgst_amt);
  setigst_per_er(response.data.validatorerror.Igst_per);
  setigst_amt_er(response.data.validatorerror.Igst_amt);
  setattachment_link_er(response.data.validatorerror.File_attachment);
  seterror_msg("Cannot Update Transaction !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

    setdate_er(response.data.date_er);
    settype_er(response.data.type_er);
    setparticular_er(response.data.particular_er);
    setvoucher_no_er(response.data.voucher_no_er);
    setgstin_er(response.data.gstin_er);
    settitle_er(response.data.title_er);
    setamount_er(response.data.amount_er);
    settotal_er(response.data.total_er);
    setbank_id_er(response.data.bank_id_er);
    setdiscount_per_er(response.data.discount_per_er);
    setdiscount_amt_er(response.data.discount_amt_er);
    setsgst_per_er(response.data.sgst_per_er);
    setsgst_amt_er(response.data.sgst_amt_er);
    setcgst_per_er(response.data.cgst_per_er);
    setcgst_amt_er(response.data.cgst_amt_er);
    setigst_per_er(response.data.igst_per_er);
    setigst_amt_er(response.data.igst_amt_er);
    setattachment_link_er(response.data.file_attachment_er);
  seterror_msg("Cannot Update Transaction !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Update Transaction !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** EDIT TRANSACTION FUNCTION END  ******************/


function calculate(){
  
  //Fetching Values
  let amount = (document.getElementById("amount").value);if(amount==""){ amount=0;}else{ amount=parseFloat(amount);}
  let discount_per = (document.getElementById("discount_per").value);if( discount_per==""){ discount_per=0;}else{ discount_per=parseFloat(discount_per);}
  let discount_amt = (document.getElementById("discount_amt").value);if(discount_amt==""){ discount_amt=0;}else{ discount_amt=parseFloat(discount_amt);}
  let sgst_per = (document.getElementById("sgst_per").value);if( sgst_per==""){ sgst_per=0;}else{ sgst_per=parseFloat(sgst_per);}
  let sgst_amt = (document.getElementById("sgst_amt").value);if(sgst_amt==""){ sgst_amt=0;}else{ sgst_amt=parseFloat(sgst_amt);}
  let cgst_per = (document.getElementById("cgst_per").value);if( cgst_per==""){ cgst_per=0;}else{ cgst_per=parseFloat(cgst_per);}
  let cgst_amt = (document.getElementById("cgst_amt").value);if(cgst_amt==""){ cgst_amt=0;}else{ cgst_amt=parseFloat(cgst_amt);}
  let igst_per = (document.getElementById("igst_per").value);if( igst_per==""){ igst_per=0;}else{ igst_per=parseFloat(igst_per);}
  let igst_amt = (document.getElementById("igst_amt").value);if(igst_amt==""){ igst_amt=0;}else{ igst_amt=parseFloat(igst_amt);}


  let total=(amount);
  if(amount>0){
    //Discount Calculation
    if(discount_per>0){discount_amt = ((amount)*(discount_per)/100);}
    let after_discount = amount-discount_amt;
   //Gst and Tax Calculation
   if(sgst_per>0){sgst_amt = ((after_discount)*(sgst_per)/100);}
   if(cgst_per>0){cgst_amt = ((after_discount)*(cgst_per)/100);}
   if(igst_per>0){igst_amt = ((after_discount)*(igst_per)/100);}
   
   //Setting Final Values to display
    total = (after_discount+sgst_amt+cgst_amt+igst_amt);setdiscount_amt(discount_amt);setsgst_amt(sgst_amt);setcgst_amt(cgst_amt);setigst_amt(igst_amt);settotal(total);

}
}

  
return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/adminpanel-dashboard-expenses"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/adminpanel-dashboard-expenses")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">EDIT EXPENSE TRANSACTION</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


            <div class="card" style={{textAlign:"left"}}>
              
              <div class="card-body">
                <h4 class="card-title">Edit Expense</h4>
                <div class="form-sample">
                  <p class="card-description">edit your expense details here</p>
                  <code>{error_msg}</code>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Date</label>
                        <div class="col-sm-9">
                          <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                          <code>{date_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Voucher No.</label>
                        <div class="col-sm-9">
                        <input type="text" class="form-control" placeholder="VN87766" id="voucher_no" value={voucher_no} onChange={updatevoucher_no} />
                        <code>{voucher_no_er}</code>
                        </div>
                      </div>
                    </div>   
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">GSTIN</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="HGSSSFS55S4S4S4" id="gstin" value={gstin} onChange={updategstin} />
                          <code>{gstin_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Title</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="NEW INCOME TITLE" id="title" value={title} onChange={updatetitle}  />
                          <code>{title_er}</code>
                        </div>
                      </div>
                    </div>                     
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Type</label>
                        <div class="col-sm-9">
                          <select class="form-control" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype}>
                          <option value=" "> -- select type -- </option>
                          <option value="cash">cash</option>
                          <option value="bank">bank</option>
                          </select>
                          <code>{type_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                            <option value=""> -- select bank -- </option>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                            <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                            </select>
                            <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div> 

                  </div>
                
               
             
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Amount</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="2000.00" id="amount" value={amount} onChange={updateamount}  />
                          <code>{amount_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Total</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="1300.00" id="total" value={total} onChange={updatetotal} />
                          <code>{total_er}</code>
                         </div>
                      </div>
                    </div>                     
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Discount %</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="50.00" id="discount_per" value={discount_per} onChange={updatediscount_per} />
                          <code>{discount_per_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Discount Amount</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="1000.00" id="discount_amt" value={discount_amt} onChange={updatediscount_amt} />
                          <code>{discount_amt_er}</code>
                        </div>
                      </div>
                    </div>                     
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">SGST %</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="05.00" id="sgst_per" value={sgst_per} onChange={updatesgst_per} />
                          <code>{sgst_per_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">SGST Amount</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="50.00" id="sgst_amt" value={sgst_amt} onChange={updatesgst_amt} />
                          <code>{sgst_amt_er}</code>
                        </div>
                      </div>
                    </div>                     
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">CGST %</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="05.00" id="cgst_per" value={cgst_per} onChange={updatecgst_per} />
                          <code>{cgst_per_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">CGST Amount</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="50.00" id="cgst_amt" value={cgst_amt} onChange={updatecgst_amt} />
                          <code>{cgst_amt_er}</code>
                        </div>
                      </div>
                    </div>                     
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">IGST %</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="05.00" id="igst_per" value={igst_per} onChange={updateigst_per} />
                          <code>{igst_per_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">IGST Amount</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="50.00" id="igst_amt" value={igst_amt} onChange={updateigst_amt} />
                          <code>{igst_amt_er}</code>
                        </div>
                      </div>
                    </div>                     
                  </div>

                  <div class="form-group">
                    <label for="exampleTextarea1">Particular</label>
                    <textarea  class="form-control" rows="2" id="particular" value={particular} onChange={updateparticular} />
                  </div>

                  <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attachment</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{attachment_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>

                    
                  
                    <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={edit_transaction}>Submit</button>
                  
                   

                 
               
                </div>
              </div>
            </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_edit_expense;