import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';



function Admin_edit_branch(){


  
    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");


 


 useEffect(()=>{
   
  getData();
  
  },[])
 


 //SETTING DATA VARIABLES
 const[branch_name,setbranch_name]=useState();const updatebranch_name = (event) => { setbranch_name(event.target.value);}; const[branch_name_er,setbranch_name_er]=useState();
 const[branch_code,setbranch_code]=useState();const updatebranch_code = (event) => { setbranch_code(event.target.value);}; const[branch_code_er,setbranch_code_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
 const[address,setaddress]=useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
 const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
 const[state,setstate]=useState();const updatestate = (event) => { setstate(event.target.value);}; const[state_er,setstate_er]=useState();
 const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);}; const[country_er,setcountry_er]=useState();
 const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
 const[branch_remarks,setbranch_remarks]=useState();const updatebranch_remarks = (event) => { setbranch_remarks(event.target.value);}; const[branch_remarks_er,setbranch_remarks_er]=useState();
 const[branch_status,setbranch_status]=useState('inactive');const updatebranch_status = (event) => { setbranch_status(event.target.value);}; const[branch_status_er,setbranch_status_er]=useState();
 
 
 //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();



//******************* GET BRANCH DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-get-branch-details-by-id", {
   Log_id:logid,
   Token:token,
   Edit_id:editId


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setbranch_code(response.data.branch_code);
   setbranch_name(response.data.branch_name);
   settype(response.data.type);
   setaddress(response.data.address);
   setcity(response.data.city);
   setstate(response.data.state);
   setpincode(response.data.pincode);
   setbranch_remarks(response.data.branch_remarks);
   setbranch_status(response.data.branch_status);

  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET BRANCH DATA FUNCTION END  ********************//

 
 
/***************** EDIT BRANCH FUNCTION START  ******************/
function edit_branch(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(editId>0)){


    //Setting Error Variables
    setbranch_name_er(" ");setbranch_code_er(" ");settype_er(" "); setaddress_er(" ");setcity_er(" ");setstate_er(" ");setcountry_er(" ");setpincode_er(" ");setbranch_remarks_er(" ");seterror_msg(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $branch_name = document.getElementById("branch_name").value; if($branch_name==""){ setbranch_name_er("Branch Name is required !");noerror = noerror+1; }//branch_name
const $branch_code = document.getElementById("branch_code").value; if($branch_code==""){ setbranch_code_er("Branch Code is required !");noerror = noerror+1; }//branch_code
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//type
const $address = document.getElementById("address").value; if($address==""){ setaddress_er("Address is required !");noerror = noerror+1; }//address
const $city = document.getElementById("city").value; if($city==""){ setcity_er("City is required !");noerror = noerror+1; }//city
const $state = document.getElementById("state").value; if($state==""){ setstate_er("State is required !");noerror = noerror+1; }//state
const $pincode = document.getElementById("pincode").value; if($pincode==""){ setpincode_er("Pincode is required !");noerror = noerror+1; }//pincode
const $branch_remarks = document.getElementById("branch_remarks").value; if($branch_remarks==""){ setbranch_remarks_er("Branch Remarks is required !");noerror = noerror+1; }//branch_remarks



//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-edit-branch-details", { 
  Log_id:logid,
  Token:token,
  Branch_name:$branch_name,
  Branch_code:$branch_code,
  Type:$type,
  Address:$address,
  City:$city,
  State:$state,
  Country:"India",
  Pincode:$pincode,
  Branch_remarks:$branch_remarks,
  Branch_status:branch_status,
  Edit_id:editId


  }
    )
  
  .then((response) => {

    console.log(response);
  
  //Validation Errors
  if(response.data.validatorerror){
  setbranch_name_er(response.data.validatorerror.Branch_name);
  setbranch_code_er(response.data.validatorerror.Branch_code);
  settype_er(response.data.validatorerror.Type);
  setaddress_er(response.data.validatorerror.Address);
  setcity_er(response.data.validatorerror.City);
  setstate_er(response.data.validatorerror.State);
  setcountry_er(response.data.validatorerror.Country);
  setpincode_er(response.data.validatorerror.Pincode);
  setbranch_remarks_er(response.data.validatorerror.Branch_remarks);
  seterror_msg("Cannot Update Branch Details !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  setbranch_name_er(response.data.branch_name_er);
  setbranch_code_er(response.data.branch_code_er);
  settype_er(response.data.type_er);
  setaddress_er(response.data.address_er);
  setcity_er(response.data.city_er);
  setstate_er(response.data.state_er);
  setcountry_er(response.data.country_er);
  setpincode_er(response.data.product_details_er);
  setbranch_remarks_er(response.data.branch_remarks_er);
  seterror_msg("Cannot Update Branch Details !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; sessionStorage.removeItem("EditId00"); }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Update Branch Details !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 sessionStorage.removeItem("EditId00");
 window.location.replace("/");
 
 
 }    

}

/***************** EDIT BRANCH FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Branch Details Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-dashboard-branch"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/admin-dashboard-branch")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">EDIT BRANCH DETAILS</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Edit Branch Details</h4>
                    <div class="form-sample">
                      <p class="card-description"><code>{error_msg}</code></p>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LS1234567" id="branch_code" value={branch_code}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                              <code>{branch_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular Rural Loan" id="branch_name" value={branch_name} onChange={updatebranch_name} />
                              <code>{branch_name_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2" placeholder="here details"  id="address" value={address} onChange={updateaddress} />
                        <code>{address_er}</code>
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Type"  id="type" value={type} onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna"  id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh"  id="state" value={state} onChange={updatestate} />
                              <code>{state_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="482330"  id="pincode" value={pincode} onChange={updatepincode} />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        
                      <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="here remarks"  id="branch_remarks" value={branch_remarks} onChange={updatebranch_remarks} />
                        <code>{branch_remarks_er}</code>
                      </div>

                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="branch_status" value={branch_status} onChange={updatebranch_status}>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{branch_status_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={edit_branch}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_edit_branch;