import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';


function Admin_loan_approval(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");
 const custId=sessionStorage.getItem("CustomerId00");
 const appId=sessionStorage.getItem("AppId00");
 const editId=sessionStorage.getItem("EditId00");
 const loan_amt=sessionStorage.getItem("Amt00");



 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 
 //Error Variable
 const[errorvar,seterrorvar] = useState();
 
 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


useEffect(()=>{
   
    getData();
    
    },[])


    

//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("crm-admin-get-banks-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//

//DATA VARIABLES
 const[transfer_date,settransfer_date]=useState();const updatetransfer_date = (event) => { settransfer_date(event.target.value);}; const[transfer_date_er,settransfer_date_er]=useState();
 const[emi_date,setemi_date]=useState();const updateemi_date = (event) => { setemi_date(event.target.value);}; const[emi_date_er,setemi_date_er]=useState();
 const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
 const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
 const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
 const[remarks,setremarks]=useState();const updateremarks = (event) => { setremarks(event.target.value);}; const[remarks_er,setremarks_er]=useState();

  
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

 


/***************** LOAN APPROVAL APPLICATION FUNCTION START  ******************/
function approve_loan_application(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(editId>0)&&((appId!=null)||(appId!=" "))){

    //Setting Error Variables
    settransfer_date_er(" ");setemi_date_er(" ");setmode_er(" ");setbank_id_er(" ");setbank_name_er(" ");setremarks_er(" ");
    seterror_msg(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $transfer_date = document.getElementById("transfer_date").value; if($transfer_date==""){ settransfer_date_er("Amount Transfer Date is required !");noerror = noerror+1; }//transfer_date
const $emi_date = document.getElementById("emi_date").value; if($emi_date==""){ setemi_date_er("EMI Date is required !");noerror = noerror+1; }//emi_date
const $mode = document.getElementById("mode").value; if($mode==""){ setmode_er("Mode is required !");noerror = noerror+1; }//mode
const $bank_id = document.getElementById("bank_id").value; //if($bank_id==""){ setbank_id_er("Bank Name is required !");noerror = noerror+1; }//bank_id
const $remarks = document.getElementById("remarks").value; if($remarks==""){ setremarks_er("Remarks is required !");noerror = noerror+1; }//remarks


if($transfer_date==$emi_date){ seterror_msg("Amount Transfer and EMI Start Date Cannot be same!"); noerror = noerror+1; }

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-admin-approve-loan-application", { 
  Log_id:logid,
  Token:token,
  Edit_id:editId,
  Application_no:appId,
  Transfer_date:moment($transfer_date).format('YYYY-MM-DD'),
  EMI_date:moment($emi_date).format('YYYY-MM-DD'),
  Mode:$mode,
  Bank_id:$bank_id,
  Remarks:$remarks,
    }
    )
  
  .then((response) => {


  //Validation Errors
  if(response.data.validatorerror){
  settransfer_date_er(response.data.validatorerror.Transfer_date);
  setemi_date_er(response.data.validatorerror.Emi_date);
  setmode_er(response.data.validatorerror.Mode);
  setbank_id_er(response.data.validatorerror.Bank_id);
  setremarks_er(response.data.validatorerror.Remarks);
  seterror_msg("Cannot Approve Loan Application !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    settransfer_date_er(response.data.transfer_date_er);
    setemi_date_er(response.data.emi_date_er);
    setmode_er(response.data.mode_er);
    setbank_id_er(response.data.bank_id_er);
    setremarks_er(response.data.remarks_er);
    seterror_msg("Cannot Approve Loan Application !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ 
    
    document.getElementById("success-card").style.display="block"; 

    sessionStorage.removeItem("AppId00");
    sessionStorage.removeItem("EditId00");
    sessionStorage.removeItem("Amt00");
    
  }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Approve Loan Application !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }

}
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 sessionStorage.removeItem("AppId00");
 sessionStorage.removeItem("EditId00");
 sessionStorage.removeItem("Amt00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** APPROVE LOAN APPLICATION FUNCTION END  ******************/


 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Loan Request Approved Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/adminpanel-dashboard-customers"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/adminpanel-dashboard-view-loan-application")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">LOAN APPROVAL AND PAYMENT</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Loan Request</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Loan Application No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="C656666" value={appId} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LS1234567" value={loan_amt}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount Transfer Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="transfer_date" value={transfer_date} onChange={updatetransfer_date} />
                              <code>{transfer_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">EMI Start Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="emi_date" value={emi_date} onChange={updateemi_date} />
                              <code>{emi_date_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Mode</label>
                        <div class="col-sm-9">
                          <select class="form-control" style={{padding:"1rem"}} id="mode" value={mode} onChange={updatemode}>
                          <option value=" "> -- select mode -- </option>
                          <option value="cash">cash</option>
                          <option value="bank">bank</option>
                          </select>
                          <code>{mode_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                            <option value=""> -- select bank -- </option>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                            <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                            </select>
                            <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div> 

                  </div>
                       <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="remarks here" id="remarks" value={remarks} onChange={updateremarks} />
                        <code>{remarks_er}</code>
                       </div>
                        <div>
                        

                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={approve_loan_application}>Submit</button>
                     

                        </div>

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                       
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_loan_approval;