import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import { useLocation } from 'react-router-dom';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Admin_offer_letter(){

   //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';
 

  

    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });


//Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");

  const location = useLocation();
  const { date, offer_id, employee_code, employee_name, working_city, working_state, date_of_joining, join_before, department, designation, reporting_manager, gross_salary, gross_salary_inwords, basic_salary, house_rental_allowance, travel_allowance, dearance_allowance, special_allowance, incentives, deductions, pf_contribution, fixed_ctc, hr_manager, incentive_remarks, address, city, hstate, pincode, hemail, hcontact } = location.state || {};

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

      

          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/admin-dashboard-create-offer-letter")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">OFFER LETTER</p>
                  </a>
                </div>
           
            </div>
            </div>


                <div class="card"  id="invoice" ref={componentRef}>

              
                  <div class="card-body" style={{textAlign:"left"}}>

                  <div class="offer-letter-head">
                  <div style={{flexDirection:"row", display:"flex"}}>
                  <div style={{width:"25%"}} >
                    <img src="images/crmlogo.png" alt="profile" style={{width:"16rem", height:"16rem"}} />
                  </div>
                  <div class="row" style={{width:"75%"}}>

                    <div class="row col-md-12">
                    <h1 style={{color:"#038d8e", marginBottom:"0", textAlign:"center"}}><b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b></h1>
                    
                    <p style={{marginTop:"0", textAlign:"center"}}><b>Address:</b> {address} <br/>
                    {city} ({hstate}), ({pincode}) <br/><br/>
                    <b>Email:</b> {hemail}, <b>Contact:</b> {hcontact}</p>

                  
                    </div>

                   {/*} <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{width:"50%", fontSize:"1rem"}}> <p><b>Office Address:</b>   HNO 5 Sirsaha Village<br/>
                    Majhagawan, Satna (Madhya Pradesh, India)</p>
                    </div>

                 

                  <div style={{borderLeft:"0.1rem solid #038d8e", width:"50%"}}> 
                    <div class="row" style={{marginLeft:"1rem"}}>
                    <div class="row col-sm-4"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-8"> <p>+91 8349834849</p></div>
                    </div>
                    <div class="row" style={{marginLeft:"1rem"}}>
                    <div class="row col-sm-4"> <p><b>Email:</b></p></div>
                    <div class="row col-sm-8"> <p>hr@pragatiprathmik.com</p></div>
                    </div>
                    <div class="row" style={{marginLeft:"1rem"}}>
                    <div class="row col-sm-4"> <p><b>Website:</b></p></div>
                    <div class="row col-sm-8"> <p>https://pragatiprathmik.com</p></div>
                    </div>
                    </div> 


                    </div>  */}
                 
                
                  </div> 
                  </div> 

                  </div>
                  <div style={{backgroundColor:"#038d8e", padding:"0.15rem", marginTop:"1rem"}}></div>

                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  {/* OFFER LETTER CONTENT SECTION START */}
                  <div class="" style={{padding:"2rem", fontSize:"1rem"}}>
                  <h2 style={{textAlign:"center"}}>Offer Letter</h2>
                
                  <p style={{textAlign:"right"}}><b>Date: </b> {date}</p>
                  <p><b>Offer Letter Id :  {offer_id} </b></p>
                 

                  <p><b>Employee Code: {employee_code} </b></p>
	
                  <p><b>Employee Name : {employee_name} </b></p>

                  <p>With reference to your application and subsequent discussions you had with us, we are pleased to offer you an appointment with Pragati Prathmik Sakh Sahkari Samiti Maryadit (“Company”), on the following terms and conditions:	
                 </p>
                 <p>1. <b>Date of Joining & Work Location:</b> Your appointment becomes effective from the date of joining the services of the Company that is <b>{date_of_joining}</b>, which date shall be no later than <b>{join_before}</b> </p>
                 <br/><br/>
                 <p>2. <b>Department, Designation & Reporting Manager:</b> 
                 <br/>Department: {department}
		             <br/>Designation: {designation}
			           <br/>Reporting Manager: {reporting_manager}
                 <br/><br/>
                 <p>3. Your Total Gross Salary Payable to you will be <b>Rs. {gross_salary} ({gross_salary_inwords})</b>. The detailed breakdown of salary is as follows:</p>
		             <br/><br/>
                 <table class="table table-bordered">
                 <thead>
                  <tr>
                    <th><b>Description</b></th>
                    <th><b>Per Month</b></th>
                    <th><b>Per Year</b></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Basic Salary</td>
                    <td>{basic_salary}</td>
                    <td>{basic_salary*12}</td>
                  </tr>
                  <tr>
                    <td>House Rental Allowance (HRA)</td>
                    <td>{house_rental_allowance}</td>
                    <td>{house_rental_allowance*12}</td>
                  </tr>
                  <tr>
                    <td>Travel Allowance (TA)</td>
                    <td>{travel_allowance}</td>
                    <td>{travel_allowance*12}</td>
                  </tr>
                  <tr>
                    <td>Dearance Allowance (DA)</td>
                    <td>{dearance_allowance}</td>
                    <td>{dearance_allowance*12}</td>
                  </tr>
                  <tr>
                    <td>Special Allowance (SA)</td>
                    <td>{special_allowance}</td>
                    <td>{special_allowance*12}</td>
                  </tr>
                  <tr>
                    <td>Incentives</td>
                    <td>{incentives}</td>
                    <td>{incentives*12}</td>
                  </tr>
                  <tr>
                    <td>Deductions</td>
                    <td>{deductions}</td>
                    <td>{deductions*12}</td>
                  </tr>
                  <tr>
                    <td><b>GROSS SALARY</b></td>
                    <td><b>{gross_salary}</b></td>
                    <td><b>{gross_salary*12}</b></td>
                  </tr>
                  <tr>
                    <td>Employer PF Contribution</td>
                    <td>{pf_contribution}</td>
                    <td>{pf_contribution*12}</td>
                  </tr>
                  <tr>
                    <td><b>TOTAL FIXED CTC</b></td>
                    <td><b>{fixed_ctc}</b></td>
                    <td><b>{fixed_ctc*12}</b></td>
                  </tr>
                  </tbody>
                 </table>
                 <br/><br/>
                 <p><b>Note: </b>{incentive_remarks}*</p>
                 <br/><br/>
                <p>4. <b>Company Policies:</b> You will be governed by the Company’s policies, regulations and procedures on the office timings, anti-sexual harassment, leave, travel, transfers,  misconduct, etc., presently in force or as introduced/amended from time to time. </p>

                <br/><br/>
                <p> 5. <b>Termination:</b> Your services may be terminated in the following manner:</p>

                <p> a) The Company will be entitled to terminate your services by giving you 48 hours’ notice in writing. In the event you desire to leave the services of the Company, you will be required to give the Company 2 months notice in writing. </p>
	              <br/>
               <p> b) In the event of termination on disciplinary grounds including but not limited to embezzlement, fraud, gross negligence, willful misconduct, or a material violation of Company policies or you are found to be absconding from the services of the Companyor for any other reasons causing grievous loss / damage / disrepute to the Company / associates, or customers your termination will be immediate and without any notice or compensation.
               </p><br/>
                <p>c) In the event of your resignation from the services of the Company, you will be required to give the Company 2 months written notice. The Company shall have the right to deduct the salary in lieu of the notice period and you will not be eligible to be hired by 
                the Company in future. You shall, on ceasing to be an employee of the Company for any reason and in addition to the obligations under the Non-Disclosure and Confidential  
                Information clause(if any) in this Agreement, forthwith return all Company properties, movable and immovable, including all Company information and data in any form, files, reports, 
		            memoranda, software, credit cards, door and file keys, computer access codes, laptops, desktops, and such other property which you received or in possession or prepared in 
	              connection with your employment with the Company.</p>
		          	<br/>
		          	<br/>
                <p>6. <b>Confidential Information:</b> As an employee, you may come in to possession of information confidential to the Company and to its client and agree to keep confidential, 
		            Company’s proprietary and confidential information obtained at any time during the period of your employment in the Company. Confidential information includes, and is 
                not limited to; financial documents and other relevant documents of the company and of its client. You shall not disclose such Confidential Information to any person. You shall not make any copies of the Confidential Information. You shall not disclose, reproduce 
                or use any Confidential Information for any purpose except solely in connection with your performance in company. </p> 
			          <br/>
			          <br/>
                <p>7. <b>Indemnity:</b> You hereby agree to indemnify and keep indemnified and hold the Company harmless from and against any loss, claim, damage, costs, taxes, duties, additions, penalties, interest thereon or expenses of any kind, including reasonable 
                attorney’s fees, incurred/sustained or caused to be incurred/sustained by the Company on account of:
                </p>

             <p>a. Any act or omission by you;</p>

              <p>b. Contravention of any of the terms, conditions, covenants of this letter or the NonDisclosure and Confidential Information clause of this Agreement;</p>
              <p>c. Any representation or warranty or information furnished to the Company found to be false;</p>
              <p>d. Violation/non-compliance with any laws/rules/regulations while rendering the services; and/or</p>
              <p>e. Failure to adhere to the standards/specifications/policies of the Company.</p>
	 
              <p>8. <b>General Provisions: </b></p>
              <p>a. You are required to devote your time, attention and effort to the furtherance of the business of the Company and to continually develop your professional skills in the 
               interest of the Company and yourself. Further, you shall not divulge, communicate or pass any information in any form, related to any aspect of the Company to anyone outside the Company.</p>
              <p>
              b. You shall endeavor to uphold the good image of the Company and shall not by your conduct adversely affect the reputation of the Company and bring disrepute to the 
              Company, in any manner whatsoever. You shall not conduct yourself in any manner amounting to breach of confidence reposed in you or inconsistent with the position of 
              responsibility occupied by you. You shall at all times deal with the Company’s money, material and documents with utmost honesty and professional ethics.
              </p>
              <p>c. Your individual remuneration is purely a matter between yourself and the Company and has been arrived at on the basis of our specific background and professional merit. 
              The Company expects that you maintain this information and any future changes to your remuneration, as strictly personal and confidential.</p>
              <p>d. During the course of your employment, if you, at any time render yourself incompetent to perform your duties or if you should misconduct yourself or be disobedient, 
              intemperate, irregular in attendance, commit breach of the terms of your employment or of any of the stipulations herein contained, the Company shall without prejudice to any 
              of its rights under the terms herein contained, be entitled to terminate your employment forthwith without notice or payment in lieu of notice and deduct from your salary or 
              other emoluments, if any, then due to you, including the amount of any damage that the Company may have sustained
              </p>
			        <p>f. You will be subject to the Company’s rules and regulations for the time being in force and as varied from time to time.</p>
              <p>g. This letter constitutes the complete understanding between you and the Company regarding the terms of your employment with the Company. This supersedes any and all 
              ther agreements, either written or oral, between you and the Company regarding your 
              employment. Any modification of this letter will be effective only if it is in writing, signed by both parties.
			        </p>
              <p></p>h. All disputes arising herein shall be governed by the laws of India and the jurisdiction to entertain and try such dispute shall vast exclusively in the courts of <b>Satna, Madhya Pradesh</b>
		

		         	<br/>
	        		<br/>
              <p>The terms of your employment contract detailed above are strictly confidential and should be treated as privileged information between yourself and the Company. You are 
              expected to maintain such information appropriately. You are requested to signify your acceptance of the terms and conditions by signing and returning to us the duplicate copy of this letter.</p>

              <p>We look forward to you joining us at the earliest. We are certain that you will find challenge, satisfaction and opportunity in your association with the Company. </p>
              <p>You are requested to submit the signed copy of the below mentioned documents on your joining date</p>

              <p>1. 12th Mark sheet/ Graduation/Post Graduation Mark sheet</p>
			
              <p>2. Pan Card</p>
			
              <p>3. Aadhaar Card</p>
			
              <p>4. Cancelled Cheque/Bank Statement/Bank Passbook</p>
			
              <p>5. Passport Size Photograph</p>
			        <br/>
              <br/>
              <p>Yours sincerely,</p>
		        	<br/>
              <b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
		        	<br/>
              <p>Human Resource Signature:</p>
              <br/>
              <img src="images/hr_sign.jpeg" alt="profile" style={{width:"18rem", height:"6rem"}} />
              <br/>
              <br/>
              <br/>
              <b>HR Name: {hr_manager}</b>
		         	<br/>
              <br/>
               <p>Accept Job Offer by signing below</p>
		        	<br/>
              <br/>
              <br/>
              <p>I hereby declare the information provided to the comapny is true and I take complete responsibility of any .....</p>
              <br/>
              <br/>
              <br/>
              <br/>
              <p><b>{employee_name}</b></p>

            
	          	</p>
              </div>


               </div>
               </div>
                
               <button type="button" style={{backgroundColor:"#196fb0",marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
               </button>
               <br/>
               <br/>
               <br/>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_offer_letter;