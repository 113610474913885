import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './Supportingfiles/navbar.js';
import Sidebar from './Supportingfiles/sidebar.js';
//import Create_loanrequests from './create_newaccounts';



function Employee_disbursalcollection(){

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const edit_id=sessionStorage.getItem("DisId00");

 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


const[application_no,setapplication_no]=useState();
const[branch_code,setbranch_code]=useState();
const[application_date,setapplication_date]=useState();
const[disbursal_date,setdisbursal_date]=useState();
const[group_name,setgroup_name]=useState();
const[account_number,setaccount_number]=useState();
const[customer_id,setcustomer_id]=useState();
const[first_name,setfirst_name]=useState();
const[last_name,setlast_name]=useState();
const[contact,setcontact]=useState();
const[coapplicant_name,setcoapplicant_name]=useState();
const[loan_amount,setloan_amount]=useState();
const[scheme_code,setscheme_code]=useState();
const[duration,setduration]=useState();
const[disbursement_status,setdisbursement_status]=useState();
const[processing_fee_collected,setprocessing_fee_collected]=useState();
const[insurance_collected,setinsurance_collected]=useState();
const[pf_collected,setpf_collected]=useState();
const[processing_fee,setprocessing_fee]=useState();
const[pf_per,setpf_per]=useState();
const[pf_amount,setpf_amount]=useState();
const[death_insurance,setdeath_insurance]=useState();
const[health_insurance,sethealth_insurance]=useState();
const[insurance_gst,setinsurance_gst]=useState();
const[gst_per,setgst_per]=useState();
const[insurance_total,setinsurance_total]=useState();

useEffect(()=>{
   
    getData();
    
    },[])


    

//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(edit_id>0)){
 
 axios.post("crm-employee-disbursal-collection-default-load", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,
   'Edit_id' : edit_id


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
   setapplication_no(response.data.application_no);
   setbranch_code(response.data.branch_code);
   setapplication_date(response.data.application_date);
   setdisbursal_date(response.data.disbursal_date);
   setgroup_name(response.data.group_name);
   setaccount_number(response.data.account_number);
   setcustomer_id(response.data.customer_id);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setcontact(response.data.contact);
   setcoapplicant_name(response.data.coapplicant_name);
   setloan_amount(response.data.loan_amount);
   setscheme_code(response.data.scheme_code);
   setduration(response.data.duration);
   setdisbursement_status(response.data.disbursement_status);
   setprocessing_fee_collected(response.data.processing_fee_collected);
   setinsurance_collected(response.data.insurance_collected);
   setpf_collected(response.data.pf_collected);
   setprocessing_fee(response.data.processing_fee);
   setpf_per(response.data.pf_per);
   setpf_amount(response.data.pf_amount);
   sethealth_insurance(response.data.health_insurance);
   setdeath_insurance(response.data.death_insurance);
   setgst_per(response.data.gst_per);
   setinsurance_gst(response.data.insurance_gst);
   setinsurance_total(response.data.insurance_total);

  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//


 
  //SETTING DATA VARIABLES
  const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
  const[transaction,settransaction]=useState();const updatetransaction = (event) => { settransaction(event.target.value);}; const[transaction_er,settransaction_er]=useState();
  const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
  const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);}; const[amount_er,setamount_er]=useState();
  const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
  const[bank_name,setbank_name]=useState();//const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();
  
//Setting variables for processing fee payment
  const[date1,setdate1]=useState();const updatedate1 = (event) => { setdate1(event.target.value);}; const[date1_er,setdate1_er]=useState();
  const[mode1,setmode1]=useState();const updatemode1 = (event) => { setmode1(event.target.value);}; const[mode1_er,setmode1_er]=useState();
  const[particular1,setparticular1]=useState();const updateparticular1 = (event) => { setparticular1(event.target.value);}; const[particular1_er,setparticular1_er]=useState();
  const[bank_id1,setbank_id1]=useState();const updatebank_id1 = (event) => { setbank_id1(event.target.value);}; const[bank_id1_er,setbank_id1_er]=useState();
  const[bank_name1,setbank_name1]=useState();//const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();

  //Setting variables for pf payment
  const[date2,setdate2]=useState();const updatedate2 = (event) => { setdate2(event.target.value);}; const[date2_er,setdate2_er]=useState();
  const[mode2,setmode2]=useState();const updatemode2 = (event) => { setmode2(event.target.value);}; const[mode2_er,setmode2_er]=useState();
  const[particular2,setparticular2]=useState();const updateparticular2 = (event) => { setparticular2(event.target.value);}; const[particular2_er,setparticular2_er]=useState();
  const[bank_id2,setbank_id2]=useState();const updatebank_id2 = (event) => { setbank_id2(event.target.value);}; const[bank_id2_er,setbank_id2_er]=useState();
  const[bank_name2,setbank_name2]=useState();//const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();

//Setting variables for insurance payment
const[date3,setdate3]=useState();const updatedate3 = (event) => { setdate3(event.target.value);}; const[date3_er,setdate3_er]=useState();
const[mode3,setmode3]=useState();const updatemode3 = (event) => { setmode3(event.target.value);}; const[mode3_er,setmode3_er]=useState();
const[particular3,setparticular3]=useState();const updateparticular3 = (event) => { setparticular3(event.target.value);}; const[particular3_er,setparticular3_er]=useState();
const[bank_id3,setbank_id3]=useState();const updatebank_id3 = (event) => { setbank_id3(event.target.value);}; const[bank_id3_er,setbank_id3_er]=useState();
const[bank_name3,setbank_name3]=useState();//const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();


const[transaction_type,settransaction_type]=useState("processing fee received");const updatetransaction_type = (event) => { settransaction_type(event.target.value);}; const[transaction_type_er,settransaction_type_er]=useState();


 //image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
 const[attachment_link_er,setattachment_link_er]=useState();

 
   
 
/***************** CREATE TRANSACTION FUNCTION START  ******************/
function create_transaction(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(edit_id)){


    //Setting Error Variables
    setdate_er(" ");settransaction_er(" ");setparticular_er(" ");setamount_er(" ");setbank_id_er(" "); seterror_msg(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $date = document.getElementById("date").value; if($date==""){ setdate_er("Date is required !");noerror = noerror+1; }//date
const $transaction = document.getElementById("transaction").value; if($transaction==""){ settransaction_er("Transaction is required !");noerror = noerror+1; }//transaction
const $transaction_type = document.getElementById("transaction_type").value; if($transaction_type==""){ settransaction_type_er("Transaction is required !");noerror = noerror+1; }//transaction

//const $particular = document.getElementById("particular").value; if($particular==""){ setparticular_er("Particular is required !");noerror = noerror+1; }//particular
//const $amount = document.getElementById("amount").value; if($amount==""){ setamount_er("Amount is required !");noerror = noerror+1; }//amount
const $bank_id = document.getElementById("bank_id").value; //if($bank_id==""){ setbank_id_er("Bank is required !");noerror = noerror+1; }//bank_id

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-employee-disbursal-manage-collection", { 
  Log_id:logid,
  Token:token,
  Date:$date,
  Mode:$transaction,
  //Particular:$particular,
  //Amount:$amount,
  Type:$transaction_type,
  Bank_id:$bank_id,
  //File_attachment:selectedFile,
  Edit_id:edit_id
 
  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

  //Validation Errors
  if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  settransaction_type_er(response.data.validatorerror.Type);
  settransaction_er(response.data.validatorerror.Mode);
  //setparticular_er(response.data.validatorerror.Particular);
  //setamount_er(response.data.validatorerror.Amount);
  setbank_id_er(response.data.validatorerror.Bank_id);
 // setattachment_link_er(response.data.validatorerror.File_attachment);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

  setdate_er(response.data.date_er);
  settransaction_type_er(response.data.type_er);
  settransaction_er(response.data.mode_er);
  setbank_id_er(response.data.bank_id_er);
 // setparticular_er(response.data.particular_er);
 // setamount_er(response.data.amount_er);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Add Transaction !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE TRANSACTION FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/employee-dashboard-disbursal-collection"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-loan-disbursals")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">MANAGE DISBURSAL</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>

                    <div class="card">
                    <div className="table-responsive mt-3 mb-3">
                    <table class="table table-hover table-bordered" style={{textAlign:"center"}}>
                    <tbody>
                    <tr>
                        <td><b>Branch Code:</b></td>
                        <td>{branch_code}</td>
                        <td><b>Application No.:</b></td>
                        <td>{application_no}</td>
                    </tr>
                    <tr>
                        <td><b>Application Date:</b></td>
                        <td>{application_date}</td>
                        <td><b>Disbursal Date:</b></td>
                        <td>{disbursal_date}</td>
                    </tr>
                    <tr>
                        <td><b>Customer Id:</b></td>
                        <td>{customer_id}</td>
                        <td><b>Account Number:</b></td>
                        <td>{account_number}</td>
                    </tr>
                    <tr>
                        <td><b>Customer Name:</b></td>
                        <td>{first_name} {last_name}</td>
                        <td><b>Group Name:</b></td>
                        <td>{group_name}</td>
                    </tr>
                    <tr>
                        <td><b>Contact:</b></td>
                        <td>{contact}</td>
                        <td><b>Coapplicant Name:</b></td>
                        <td>{coapplicant_name}</td>
                    </tr>
                    <tr>
                        <td><b>Loan Amount:</b></td>
                        <td>{loan_amount}</td>
                        <td><b>Scheme Code:</b></td>
                        <td>{scheme_code}</td>
                    </tr>
                    <tr>
                        <td><b>EMIs:</b></td>
                        <td>{duration}</td>
                        <td><b>Disbursement Status:</b></td>
                        <td>  <label className="badge badge-warning" hidden={(disbursement_status=="disbursed")||(disbursement_status=="returned")}>under process</label>
                            <label className="badge badge-danger" hidden={(disbursement_status=="disbursed")||(disbursement_status=="under process")}>returned</label>
                            <label className="badge badge-success" hidden={(disbursement_status=="returned")||(disbursement_status=="under process")}>disbursed</label>
                        </td>
                    </tr>
                    </tbody>
                    </table>
                    </div>
                    </div>

            <hr/>
            <br/>
           {/*} <div class="row"> */}
           {/*}   <div class="col-md-4 mt-2"> */}
              <button class="btn btn-lg btn-primary" id="profee_collect1" style={{ marginTop:"1rem"}}  onClick={()=>{ 
             //  document.getElementById("pf-form").style.display="none";
               document.getElementById("processing-fee-form").style.display="block";
               document.getElementById("insurance-form").style.display="none";
              }}>Collect Processing Fee</button>
            {/*} </div>
           {/*}   <div class="col-md-4 mt-2">
              <button class="btn btn-lg btn-primary"  id="pf_collect1" onClick={()=>{ 
               document.getElementById("processing-fee-form").style.display="none";
              // document.getElementById("pf-form").style.display="block";
               document.getElementById("insurance-form").style.display="none";
               }}>Collect PF</button></div> */}
             {/*} <div class="col-md-4 mt-2"> */}<button class="btn btn-lg btn-primary" style={{marginLeft:"1rem", marginTop:"1rem"}}  id="insurance_collect1" onClick={()=>{ 
               document.getElementById("processing-fee-form").style.display="none";
              // document.getElementById("pf-form").style.display="none";
               document.getElementById("insurance-form").style.display="block";
              }}>Collect Insurance</button> {/* </div> */}
             {/*</div>*/}
            <br/><br/><br/>

            <input type="hidden" value={transaction_type} id="transaction_type" />

              {/* PROCESSING FEE COLLECTION CARD START */}
              <div class="card" style={{display:"block"}} id="processing-fee-form">
              
              <div class="card-body" style={{textAlign:"left"}}>
                <h4 class="card-title">Record Processing Fee Collection</h4>
                <div class="form-sample">
                  <p class="card-description">processing fee collection</p>
                  <code>{error_msg}</code>
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Date</label>
                        <div class="col-sm-9">
                          <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                          <code>{date_er}</code>
                        </div>
                      </div>
                      </div>    
                      <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Amount</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" placeholder="5000.00" id="processing_fee" value={processing_fee} />
                          <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                        </div>
                      </div>
                    </div>  
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Mode</label>
                        <div class="col-sm-9">
                        <select class="form-control" style={{padding:"1rem"}} id="transaction" value={transaction} onChange={updatetransaction}>
                        <option value="">- select mode --</option>
                        <option value="cash">cash</option>
                        <option value="bank">bank</option>
                        </select>
                        <code>{transaction_er}</code>
                        </div>
                      </div>
                    </div>   
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Bank</label>
                        <div class="col-sm-9">
                        <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                        <option value=""> -- select bank -- </option>
                        {
                   data.map((curElm,ind)=>{

                     return(
                     <>
                        <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                        </>
                     )
                     }
         
                     )
                     }
                        </select>
                        <code>{bank_id_er}</code>
                        </div>
                      </div>
                    </div> 
                    </div>
                 {/*}   <div class="form-group">
                    <label for="exampleTextarea1">Particular</label>
                    <textarea  class="form-control" rows="2" placeholder="here particular" id="particular" value={particular} onChange={updateparticular} />
                    <code>{particular_er}</code>
                  </div> */}

            

                {/*}    <div class="col-md-12">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Attachment</label>
                        <div class="col-sm-3">
                          <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                          {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                          <code>{attachment_link_er}</code>
                       
                        </div>
                      <div class="col-sm-5">
                      <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                      </div>
                    </div>
                  </div> */}
                  
                    <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={()=>{ settransaction_type("processing fee received"); create_transaction(); }}>Submit</button>
                  
                   

                 
               
                </div>
              </div>
            </div>
            {/* PROCESSING FEE COLLECTION CARD END */}
               {/* PF COLLECTION CARD START 
                <div class="card" id="pf-form"  style={{display:"none"}}>
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Record PF Collection</h4>
                    <div class="form-sample">
                      <p class="card-description">PF collected is 2% of Loan Amount i.e. {loan_amount}</p>
                      <code>{error_msg}</code>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                          </div>    
                          <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="pf_amount" value={pf_amount}  />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>  
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="transaction" value={transaction} onChange={updatetransaction}>
                            <option value="">- select mode --</option>
                            <option value="cash">cash</option>
                            <option value="bank">bank</option>
                            </select>
                            <code>{transaction_er}</code>
                            </div>
                          </div>
                        </div>   
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                            <option value=""> -- select bank -- </option>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                            <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                            </select>
                            <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                   {/*}     <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea  class="form-control" rows="2" placeholder="here particular" id="particular" value={particular} onChange={updateparticular} />
                        <code>{particular_er}</code>
                      </div> */}

                

                      {/*}  <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attachment</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{attachment_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                  {/*}      <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={()=>{ settransaction_type("PF received"); create_transaction(); }}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
                {/* PF COLLECTION CARD END */}

                {/* INSURANCE COLLECTION CARD START */}
                <div class="card"  style={{display:"none"}} id="insurance-form">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Record Insurance Collection</h4>
                    <div class="form-sample">
                      <p class="card-description">insurance being collected here</p>
                      <code>{error_msg}</code>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                          </div>    
                          <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="insurance_total" value={insurance_total} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>  
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Health Insurance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="health_insurance" value={health_insurance} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Death Insurance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="death_insurance" value={death_insurance} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="gst_per" value={gst_per} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GST on Insurance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="insurance_gst" value={insurance_gst} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="transaction" value={transaction} onChange={updatetransaction}>
                            <option value="">- select mode --</option>
                            <option value="cash">cash</option>
                            <option value="bank">bank</option>
                            </select>
                            <code>{transaction_er}</code>
                            </div>
                          </div>
                        </div>   
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                            <option value=""> -- select bank -- </option>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                            <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                            </select>
                            <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                   {/*}     <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea  class="form-control" rows="2" placeholder="here particular" id="particular" value={particular} onChange={updateparticular} />
                        <code>{particular_er}</code>
                      </div> */}

                

                       {/*} <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attachment</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{attachment_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>  */}
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={()=>{ settransaction_type("insurance received"); create_transaction(); }}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
                  {/* INSURANCE COLLECTION CARD END */}
                  <br/><br/><br/><br/><br/>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_disbursalcollection;