import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';



function Admin_create_header(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");


 //SETTING DATA VARIABLES

 const[header_title,setheader_title]=useState();const updateheader_title = (event) => { setheader_title(event.target.value);}; const[header_title_er,setheader_title_er]=useState();
 const[branch_id,setbranch_id]=useState();const updatebranch_id = (event) => { setbranch_id(event.target.value);}; const[branch_id_er,setbranch_id_er]=useState();
 const[branch_code,setbranch_code]=useState();const updatebranch_code = (event) => { setbranch_code(event.target.value);}; const[branch_code_er,setbranch_code_er]=useState();
 const[header_address,setheader_address]=useState();const updateheader_address = (event) => { setheader_address(event.target.value);}; const[header_address_er,setheader_address_er]=useState();
 const[header_city,setheader_city]=useState();const updateheader_city = (event) => { setheader_city(event.target.value);}; const[header_city_er,setheader_city_er]=useState();
 const[header_pincode,setheader_pincode]=useState();const updateheader_pincode = (event) => { setheader_pincode(event.target.value);}; const[header_pincode_er,setheader_pincode_er]=useState();
 const[header_contact,setheader_contact]=useState();const updateheader_contact = (event) => { setheader_contact(event.target.value);}; const[header_contact_er,setheader_contact_er]=useState();
 const[header_email,setheader_email]=useState();const updateheader_email = (event) => { setheader_email(event.target.value);}; const[header_email_er,setheader_email_er]=useState();
 const[header_state,setheader_state]=useState();const updateheader_state = (event) => { setheader_state(event.target.value);}; const[header_state_er,setheader_state_er]=useState();

 
 //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();


  
/***************** CREATE NEW HEADER FUNCTION START  ******************/
function create_header(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setheader_title_er(" ");setbranch_id_er(" ");setbranch_code_er(" "); setheader_email_er(" ");setheader_contact_er(" ");setheader_address_er(" ");
    setheader_city_er(" ");setheader_state_er(" ");setheader_pincode_er(" ");seterror_msg(" ");

    
//Fetching Form Data
let noerror = 0;
const $header_title = document.getElementById("header_title").value; if($header_title==""){ setheader_title_er("Header Title is required !");noerror = noerror+1; }//header_title
const $branch_id = document.getElementById("branch_id").value; if($branch_id==""){ setbranch_id_er("Branch Id is required !");noerror = noerror+1; }//branch_id
const $branch_code = document.getElementById("branch_code").value; if($branch_code==""){ setbranch_code_er("Branch Code is required !");noerror = noerror+1; }//branch_code
const $header_contact = document.getElementById("header_contact").value; //if($header_contact==""){ setheader_contact_er("Loan Amount is required !");noerror = noerror+1; }//header_contact
const $header_email = document.getElementById("header_email").value; //if($header_email==""){ setheader_email_er("Interest Rate is required !");noerror = noerror+1; }//header_email
const $header_address = document.getElementById("header_address").value; //if($header_address==""){ setheader_address_er("PF % is required !");noerror = noerror+1; }//header_address
const $header_city = document.getElementById("header_city").value; //if($header_city==""){ setheader_city_er(" PF Amount is required !");noerror = noerror+1; }//header_city
const $header_state = document.getElementById("header_state").value; //if($header_state==""){ setheader_state_er("Death Insurance is required !");noerror = noerror+1; }//header_state
const $header_pincode = document.getElementById("header_pincode").value; //if($header_pincode==""){ setheader_pincode_er("Health Insurance is required !");noerror = noerror+1; }//header_pincode



//No primary errors
if(noerror==0){

//  const $calculatedgst = $gst_amount+$loan_int_gst;

  //Sending Data through axios
  axios.post("crm-create-new-header", { 
  Log_id:logid,
  Token:token,
  Header_title:$header_title,
  Branch_id:$branch_id,
  Branch_code:$branch_code,
  Header_contact:$header_contact,
  Header_email:$header_email,
  Header_address:$header_address,
  Header_city:$header_city,
  Header_state:$header_state,
  Header_pincode:$header_pincode,

  }
    )
  
  .then((response) => {
  
  //Validation Errors
  if(response.data.validatorerror){
  setheader_title_er(response.data.validatorerror.Header_title);
  setbranch_id_er(response.data.validatorerror.Branch_id);
  setbranch_code_er(response.data.validatorerror.Branch_code);
  setheader_email_er(response.data.validatorerror.Header_email);
  setheader_contact_er(response.data.validatorerror.Header_contact);
  setheader_address_er(response.data.validatorerror.Header_address);
  setheader_city_er(response.data.validatorerror.Header_city);
  setheader_state_er(response.data.validatorerror.Header_state);
  setheader_pincode_er(response.data.validatorerror.Header_pincode);

  seterror_msg("Cannot Create Header !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    setheader_title_er(response.data.header_title_er);
    setbranch_id_er(response.data.branch_id_er);
    setbranch_code_er(response.data.branch_code_er);
    setheader_email_er(response.data.header_email_er);
    setheader_contact_er(response.data.header_contact_er);
    setheader_address_er(response.data.header_address_er);
    setheader_city_er(response.data.header_city_er);
    setheader_state_er(response.data.header_state_er);
    setheader_pincode_er(response.data.header_pincode_er);

    seterror_msg("Cannot Create Header !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Header Not Created !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE NEW HEADER FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Header Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-dashboard-headers"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/admin-dashboard-headers")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CREATE HEADER</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Header</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Header Title<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Header 1" id="header_title" value={header_title} onChange={updateheader_title} />
                              <code>{header_title_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch Id<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10" id="branch_id" value={branch_id} onChange={updatebranch_id} />
                              <code>{branch_id_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Sabhapur01" id="branch_code" value={branch_code} onChange={updatebranch_code} />
                              <code>{branch_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="452210" id="header_pincode" value={header_pincode} onChange={updateheader_pincode} />
                              <code>{header_pincode_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna" id="header_city" value={header_city} onChange={updateheader_city} />
                              <code>{header_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh" id="header_state" value={header_state} onChange={updateheader_state} />
                              <code>{header_state_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="hr@pragatiprathmik.com" id="header_email" value={header_email} onChange={updateheader_email} />
                              <code>{header_email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="918790000000" id="header_contact" value={header_contact} onChange={updateheader_contact} />
                              <code>{header_contact_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                       
                        <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2" placeholder="address here" id="header_address" value={header_address} onChange={updateheader_address} />
                        <code>{header_address_er}</code>
                        </div>

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={create_header}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_create_header;